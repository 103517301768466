import React from "react"
import { useContactInputField } from "./ContactInputFieldContext"
import InputValue from "./InputValue"

export default function InputContent() {
  const { isEditing, showLabel, handleClick, handleBlur, children } = useContactInputField()

  return isEditing ? (
    <div onBlur={handleBlur} className={showLabel ? "pt-1" : ""}>
      {children}
    </div>
  ) : (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div className="cursor-pointer" style={showLabel ? { height: 34 } : {}} onClick={handleClick}>
      <InputValue />
    </div>
  )
}
