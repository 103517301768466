import { AccessBoundary } from "@components/auth/DataAccess"
import { DatePicker } from "@stories/components/Antd"
import { Button } from "@stories/components/Button/Button"
import Divider from "@stories/components/Divider/Divider"
import { Color, Size, Typography, Weight } from "@stories/components/Typography/Typography"
import { EditIcon } from "@stories/icons/EditIcon"
import { LeftArrowIcon } from "@stories/icons/LeftArrowIcon"
import { DealCRMPriority } from "common/model/DealCRM/Deal/DealCRMDeal"
import { DealCRMContact, getCRMContactFirm } from "common/model/DealCRM/DealCRMContact"
import { articulatedDateTimeFormat } from "common/utils/dateUtils"
import { isLoaded } from "common/utils/Loading"
import moment from "moment"
import { useMemo } from "react"
import { useLocation } from "react-router-dom"
import { UpdateContactDeprecatedProps } from "src/firebase/crm"
import { PriorityDisplay } from "src/pages/CRM/Components/PriorityDisplay/PriorityDisplay"
import { UserAvatar } from "src/pages/Orders/shared/Cells"
import { useCurrentUser } from "src/providers/currentUser/useCurrentUser"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import { navItems } from "src/Routes/NavItems"
import { Routes } from "src/Routes/Routes"
import { createContactSubtitle } from "../../ContactDisplay/helpers"
import ContactAirtableLink from "../ContactAirtableLink"
import { CRMContactName } from "../CRMContactName"
import { BrokerContactDetails } from "./BrokerContactDetails"
import { FirmContactDetails } from "./FirmContactDetails"
import { IndividualContactDetails } from "./IndividualContactDetails"
import ContactInputField from "@stories/components/ContactInputField/ContactInputField"

export default function ContactDetailsDrawerSidebar({
  contact,
  backContactName,
  onBack,
  width,
  updateContact,
}: {
  contact: DealCRMContact
  backContactName?: string
  width?: number
  onBack: (goToPreviousContact?: boolean) => void
  updateContact: (p: UpdateContactDeprecatedProps<DealCRMContact>) => void
}) {
  const location = useLocation()
  const currentUser = useCurrentUser()
  const account = useCurrentAccount()

  const subContact = useMemo(() => {
    const contactFirm = getCRMContactFirm(contact)

    if (contactFirm) return contactFirm

    if (contact.tag === "firm") {
      const primaryContact = contact.contacts.find((c) => c.isPrimaryContact)
      return primaryContact
    }

    return null
  }, [contact])

  const contactSubtitle = createContactSubtitle(contact, "short")

  const previousRouteName = useMemo(() => {
    const currentRoute = navItems.find(({ route }) => route(Routes) === location.pathname)
    return (
      currentRoute?.name ||
      (isLoaded(currentUser) ? currentRoute?.getName?.(currentUser) : undefined)
    )
  }, [location.pathname, currentUser])

  const handlePriorityChange = (priority: DealCRMPriority) =>
    new Promise<void>((resolve) => {
      updateContact({
        contact,
        field: "priority",
        fieldValue: priority,
      })
      resolve()
    })

  return (
    <div
      className="flex flex-col bg-white border-r border-neutral-400 h-full"
      style={{
        minWidth: width ?? "100%",
        width: width ?? "100%",
      }}
    >
      <div className="sticky">
        <div className="flex flex-col gap-2 pt-1">
          <div>
            <Button
              label={`Back ${previousRouteName ? `to ${previousRouteName}` : ""}`}
              variant="hollow-link"
              size="medium"
              onClick={() => onBack()}
              leftIcon={<LeftArrowIcon />}
            />
          </div>
          {backContactName && (
            <div>
              <Button
                label={`Back to ${backContactName}`}
                variant="hollow-link"
                size="medium"
                onClick={() => onBack(true)}
                leftIcon={<LeftArrowIcon />}
              />
            </div>
          )}
        </div>
        <div className="flex-col p-2">
          <div className="flex gap-2">
            <div className="pt-0.5">
              <UserAvatar character={contact.name[0]} size="md" />
            </div>
            <AccessBoundary accessLevel="hideFromCaplight">
              <>
                <div className="flex flex-col">
                  <Typography text={contact.name} size={Size.Large} weight={Weight.Semibold} />
                  {subContact ? (
                    <CRMContactName contact={subContact} isIconDisplayed={false} size="none" />
                  ) : contactSubtitle ? (
                    <Typography
                      shouldMaskDataInDataDog
                      text={contactSubtitle}
                      size={Size.XSmall}
                      weight={Weight.Regular}
                      color={Color.Primary}
                    />
                  ) : null}
                  {isLoaded(account) && (
                    <ContactAirtableLink contact={contact} currentAccount={account} />
                  )}
                </div>
              </>
            </AccessBoundary>
          </div>
          <div className="flex flex-col gap-1 pt-2">
            <div className="flex justify-between items-center gap-2">
              <Typography
                text="Priority"
                size={Size.Small}
                shouldWrap={false}
                color={Color.Subtitle}
              />
              <PriorityDisplay
                editable
                priority={contact.priority ?? "none"}
                onChange={handlePriorityChange}
                noneOptionDisplayOverride={<EditIcon color={Color.Link} size="xs" />}
              />
            </div>
            <div className="flex justify-between items-center gap-2">
              <Typography
                text="Last contacted"
                size={Size.Small}
                shouldWrap={false}
                color={Color.Subtitle}
              />
              <ContactInputField
                error={false}
                label="Last contacted"
                showLabel={false}
                value={
                  contact.lastContactedAt
                    ? articulatedDateTimeFormat(moment(contact.lastContactedAt)).date
                    : undefined
                }
                saveMethod={["onBlur", "onChange"]}
              >
                <DatePicker
                  open
                  autoFocus
                  size="large"
                  className="w-full pl-0 pr-0"
                  clearIcon={false}
                  disabledDate={(d) => moment().isBefore(d)}
                  onChange={(v) =>
                    v
                      ? updateContact({
                          contact,
                          field: "lastContactedAt",
                          fieldValue: v.toDate(),
                        })
                      : undefined
                  }
                  value={contact.lastContactedAt ? moment(contact.lastContactedAt) : undefined}
                />
              </ContactInputField>
            </div>
          </div>
        </div>
        <Divider />
      </div>
      <div className="overflow-y-auto relative" style={{ flex: 1 }}>
        {contact.tag === "firm" && <FirmContactDetails contact={contact} />}
        {contact.tag === "individual" && <IndividualContactDetails contact={contact} />}
        {contact.tag === "broker" && <BrokerContactDetails contact={contact} />}
      </div>
    </div>
  )
}
