import { CurrentUser } from "@model/CurrentUser"
import { Tabs } from "@stories/components/Tabs/Tabs"
import { Account } from "common/model/Account"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { isNonBrokerageFirm } from "common/model/DealCRM/DealCRMFirmContact"
import { DealCRMInterest, DealCRMInterestWithOrigin } from "common/model/DealCRM/DealCRMInterest"
import { CONTACT_ID_SEARCH_KEY } from "common/model/DealCRM/utils/dealCRMRouteUtils"
import { isLoaded, isLoading } from "common/utils/Loading"
import React, { FC, useCallback, useMemo } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useFirebaseWriter } from "src/firebase/Context"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import {
  UpdateContactDeprecatedProps,
  createContactInterest,
  updateContactDeprecated,
} from "src/firebase/crm"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import { handleConsoleError, trackEventInFirestoreAndHeap } from "src/utils/Tracking"
import { useViewport } from "src/utils/useViewport"
import Spinner from "../../../../components/icons/Spinner"
import { useCurrentUser } from "../../../../providers/currentUser/useCurrentUser"
import { useCRMContacts } from "../../Providers/CRMContactsProvider"
import { InterestTable } from "./BuyInterest/InterestTable"
import { ContactDealsTable } from "./ContactDeals/ContactDealsTable"
import ContactDetailsDrawerNotesAndEmails from "./ContactDetailsDrawerNotesAndEmails"
import { DeleteContactButton } from "./DeleteContactButton"
import { HoldingsTableAndLink } from "./Holdings/HoldingsTable"
import { BrokerAssociates } from "./RelatedContacts/BrokerAssociates"
import { FirmAffiliatedBrokers } from "./RelatedContacts/FirmAffiliatedBrokers"
import FirmEmployeesTable from "./RelatedContacts/FirmEmployeesTable"
import { IndividualFirmAssociates } from "./RelatedContacts/IndividualFirmAssociates"
import ContactDetailsDrawerSidebar from "./Sidebar/ContactDetailsDrawerSidebar"
import { useContactBuySellInterest } from "./helpers"
import { useTopNavbarHeight } from "src/providers/TopNavbar/TopNavbarRefProvider"
import { useFeatureFlag } from "src/providers/featureFlags/useFeatureFlags"
import ContactDetailsDrawerNotes from "./ContactDetailsDrawerNotes"

export const ContactDetailsDrawerWrapper: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { findContactById } = useCRMContacts()
  const urlSearch = useMemo(() => new URLSearchParams(location.search), [location.search])

  const contactIds = useMemo(() => urlSearch.getAll(CONTACT_ID_SEARCH_KEY), [urlSearch])

  const handleBack = useCallback(
    (goToPreviousContact?: boolean) => {
      urlSearch.delete(CONTACT_ID_SEARCH_KEY)
      if (goToPreviousContact) {
        contactIds
          .slice(0, -1)
          .forEach((contactId) => urlSearch.append(CONTACT_ID_SEARCH_KEY, contactId))
      }
      navigate({ search: urlSearch.toString() })
    },
    [contactIds, urlSearch, navigate]
  )

  const contactId = contactIds?.[contactIds.length - 1]

  const backContactId = contactIds?.[contactIds.length - 2]
  const backContact = findContactById(backContactId)

  return (
    <>
      {contactId ? (
        <ContactDetailsDrawer
          contactId={contactId}
          onBack={handleBack}
          backContactName={backContact?.name}
        />
      ) : null}
      <div className={contactId ? "hidden" : ""}>{children}</div>
    </>
  )
}

const TabContent = ({ children }: { children: React.ReactNode }) => {
  const topNavbarHeight = useTopNavbarHeight()
  return (
    <div className="overflow-x-auto" style={{ height: `calc(100vh - ${topNavbarHeight}px) -38px` }}>
      {children}
    </div>
  )
}

const ContactDetailsDrawer = ({
  contactId,
  backContactName,
  onBack,
}: {
  contactId: string
  onBack: (goToPreviousContact?: boolean) => void
  backContactName?: string
}) => {
  const account = useCurrentAccount()
  const { findContactById } = useCRMContacts()
  const user = useCurrentUser()
  const currentContact = findContactById(contactId)

  const contactBuySellInterest = useContactBuySellInterest({
    contact: currentContact,
  })

  if (
    isLoading(currentContact) ||
    !isLoaded(user) ||
    !isLoaded(account) ||
    !isLoaded(contactBuySellInterest)
  )
    return <Spinner size="sm" />

  if (!currentContact)
    return (
      <div>
        Could not find contact details. This is likely an issue, please contact us at
        contact@caplight.com to let us know about the issue
      </div>
    )

  return (
    <ContactDetailsDrawerInner
      currentContact={currentContact}
      user={user}
      account={account}
      contactBuySellInterest={contactBuySellInterest}
      onBack={onBack}
      backContactName={backContactName}
    />
  )
}

const ContactDetailsDrawerInner = ({
  currentContact,
  user,
  account,
  contactBuySellInterest,
  backContactName,
  onBack,
}: {
  currentContact: DealCRMContact
  user: CurrentUser
  account: Account
  contactBuySellInterest: DealCRMInterestWithOrigin[]
  onBack: (goToPreviousContact?: boolean) => void
  backContactName?: string
}) => {
  const db = useFirebaseWriter()
  const firebase9 = useFirebase9()
  const viewport = useViewport()

  const isMobile = viewport.size === "small"

  const topNavbarHeight = useTopNavbarHeight()
  const emailFeatureEnabled = useFeatureFlag("crm_emails_integration")
  const SIDEBAR_WIDTH = 256

  const handleUpdateContactDeprecated = useCallback(
    <C extends DealCRMContact>({
      contact,
      field: contactKey,
      fieldValue: contactValue,
    }: UpdateContactDeprecatedProps<C>) =>
      updateContactDeprecated({
        db,
        contact,
        field: contactKey,
        fieldValue: contactValue,
        user: user.user,
      })
        .then(() => {
          if (
            isLoaded(user) &&
            !["contactBuyInterest", "contactSellInterest", "holdings"].includes(contactKey)
          ) {
            trackEventInFirestoreAndHeap(firebase9, user.user, "crm-contact-updated", {
              field: contactKey,
            })
          }
        })
        .catch(handleConsoleError),
    [db, user, firebase9]
  )

  const handleAddInterest = useCallback(
    async (newInterest: DealCRMInterest) => {
      if (!isLoaded(user)) return
      await createContactInterest({
        db,
        interest: newInterest,
        user: user.user,
      })
    },
    [db, user]
  )

  const memoizedRelatedContactsSections = useMemo(() => {
    const sections: React.ReactNode[] = []
    if (currentContact.tag === "individual")
      sections.push(<IndividualFirmAssociates contact={currentContact} />)

    if (currentContact.tag === "broker")
      sections.push(<BrokerAssociates contact={currentContact} />)

    if (currentContact.tag === "firm") {
      sections.push(<FirmAffiliatedBrokers contact={currentContact} />)
      if (!currentContact.isBrokerage && currentContact.postgresWebsiteDomain)
        sections.push(
          <FirmEmployeesTable postgresWebsiteDomain={currentContact.postgresWebsiteDomain} />
        )
    }

    const validSections = sections.filter((v) => v)
    return validSections.length > 0 ? <>{validSections}</> : null
  }, [currentContact])

  const memoizedTabs = useMemo(() => {
    const tabs = []

    if (isMobile) {
      tabs.push({
        id: "overview",
        key: tabs.length,
        label: "Overview",
        children: (
          <TabContent>
            <ContactDetailsDrawerSidebar
              contact={currentContact}
              onBack={onBack}
              backContactName={backContactName}
              updateContact={handleUpdateContactDeprecated}
            />
          </TabContent>
        ),
      })
    }

    tabs.push({
      id: "deals-and-orders",
      key: tabs.length,
      label: "Deals & Orders",
      children: (
        <TabContent>
          <div className="relative flex flex-col gap-4 p-6">
            <div className="absolute right-4">
              <DeleteContactButton contact={currentContact} />
            </div>
            <ContactDealsTable contact={currentContact} />
            <div className="overflow-y-scroll flex flex-col gap-4">
              {currentContact.tag === "individual" &&
              contactBuySellInterest.filter((interest) => interest.direction === "buy").length ===
                0 &&
              currentContact.classification === "shareholder" ? null : (
                <InterestTable
                  account={account}
                  contact={currentContact}
                  contactInterest={contactBuySellInterest}
                  addInterest={handleAddInterest}
                  side="buy"
                />
              )}
              <InterestTable
                account={account}
                contact={currentContact}
                contactInterest={contactBuySellInterest}
                addInterest={handleAddInterest}
                side="sell"
              />
            </div>
          </div>
        </TabContent>
      ),
    })

    if (currentContact.tag === "individual" || isNonBrokerageFirm(currentContact)) {
      tabs.push({
        id: "holdings",
        key: tabs.length,
        label: "Holdings",
        children: (
          <TabContent>
            <div className="flex flex-col gap-4 p-6">
              <HoldingsTableAndLink contact={currentContact} />
            </div>
          </TabContent>
        ),
      })
    }

    if (emailFeatureEnabled) {
      tabs.push({
        id: "notes-and-emails",
        key: tabs.length,
        label: "Notes & Emails",
        children: (
          <TabContent>
            <ContactDetailsDrawerNotesAndEmails contact={currentContact} user={user} />
          </TabContent>
        ),
      })
    } else {
      tabs.push({
        id: "notes",
        key: tabs.length,
        label: "Notes",
        children: (
          <TabContent>
            <ContactDetailsDrawerNotes contact={currentContact} />
          </TabContent>
        ),
      })
    }

    tabs.push({
      id: "related-contacts",
      key: tabs.length,
      label: "Related Contacts",
      children: (
        <TabContent>
          <div className="flex flex-col gap-4 p-6">{memoizedRelatedContactsSections}</div>
        </TabContent>
      ),
    })

    return tabs
  }, [
    isMobile,
    currentContact,
    contactBuySellInterest,
    account,
    handleAddInterest,
    user,
    memoizedRelatedContactsSections,
    onBack,
    backContactName,
    handleUpdateContactDeprecated,
    emailFeatureEnabled,
  ])

  return (
    <div
      className="flex max-w-full w-full pt-16 md:pt-0 animate-fade"
      style={{
        height: `calc(100vh - ${topNavbarHeight}px)`,
      }}
    >
      {!isMobile && (
        <ContactDetailsDrawerSidebar
          contact={currentContact}
          width={SIDEBAR_WIDTH}
          onBack={onBack}
          backContactName={backContactName}
          updateContact={handleUpdateContactDeprecated}
        />
      )}

      <div
        style={{
          flexGrow: 1,
          flexShrink: 1,
          flexBasis: 0,
          overflowX: isMobile ? "auto" : "initial",
          overflowY: "auto",
          maxWidth: !isMobile ? `calc(100% - ${SIDEBAR_WIDTH}px)` : "",
        }}
      >
        <Tabs shouldIncludeSideBorders={false} tabs={memoizedTabs} shouldWrap initialTab={0} />
      </div>
    </div>
  )
}

export default ContactDetailsDrawer
