import { CSVSchema } from "common/csv/CSVSchema"
import { useCallback, useMemo, useState } from "react"
import { Rec } from "common/utils/RecordUtils"
import { tabulateArray } from "common/utils/data/Array/ArrayUtils"
import { annotate } from "common/utils/Coerce"
import { capitalize } from "lodash"
import { AsyncDispatchButton } from "@stories/components/Button/AsyncDispatchButton"
import { Upload } from "@stories/components/Antd/Upload/Upload"
import { Button } from "@stories/components/Button/Button"
import { parseCSV } from "src/utils/ParseCSV"
import { handleConsoleError } from "src/utils/Tracking"
import { filterValidation, Validation } from "common/containers/Validation"
import { createColumnHelper } from "@stories/components/Table"
import Table from "@stories/components/Table/Table"
import { DangerIcon } from "@stories/icons/DangerIcon"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import { makeTableFilter } from "@stories/components/Table/filters/makeTableFilter"
import { UploadIcon } from "@stories/icons/UploadIcon"
import { notification } from "@stories/components/Antd/notification"
import Spinner from "@components/icons/Spinner"
import SwitchFilter from "@stories/components/Table/filters/SwitchFilter"
import pluralize from "pluralize"
import { CheckboxWithLabel } from "../checkbox/Checkbox"
import { Card } from "@stories/components/Card/Card"
import { LinkButton } from "@components/LinkButton"
import { StopClickPropagation } from "@stories/components/StopClickPropagation/StopClickPropagation"
import { StringCell } from "./CellInputOverrides/StringCell"

const camelCaseToWords = (x: string) =>
  tabulateArray((i) => x[i], x.length).reduce((words, nextChar) => {
    if (words.length === 0 || nextChar.charCodeAt(0) <= 90) {
      return words.concat([nextChar])
    } else {
      const newWords = words.slice()
      newWords[newWords.length - 1] += nextChar
      return newWords
    }
  }, annotate<string[]>([]))

const formatHeader = (h: string) => camelCaseToWords(h).map(capitalize).join(" ")

type CSVTableData<R extends Record<string, unknown>> = {
  rowError: string[] | undefined
  errorRow: boolean
  errorsDescription: string | undefined
  rowIndex: number
  cellData: {
    [k: string]: {
      data: string
      error: Partial<Record<keyof R, string>>[keyof R & string] | undefined
    }
  }
}

const ERROR_FILTER_STATE_STRING = "Errors Shown"
const errorFilter = <R extends Record<string, unknown>>(errorCount: number) =>
  makeTableFilter<CSVTableData<R>, boolean>({
    filterBy: ({ cellValue, filterState }) =>
      filterState === ERROR_FILTER_STATE_STRING ? cellValue : false,
    initialFilterStateValue: "Error",
    columnId: "errorRow",
    component: ({ setFilterState, filterState }) => (
      <SwitchFilter
        name={`Show ${errorCount} ${pluralize("error", errorCount)}`}
        active={filterState === ERROR_FILTER_STATE_STRING}
        toggle={() => setFilterState(filterState ? undefined : ERROR_FILTER_STATE_STRING)}
      />
    ),
  })

export type CSVUploaderCellRenderProps = {
  value: string
  handleChange: (value: string) => void
  error?: string
}

export const CSVUploadTable = <R extends Record<never, unknown>, T = R>(props: {
  schema: CSVSchema<R, T>
  headers: (keyof R & string)[]
  initialContents: string[][]
  submit: (r: T[]) => Promise<void>
  buttonText: (validRows: T[]) => string
  buttonSubtext?: (validRows: T[]) => string
  cellInputOverrides?: Partial<
    Record<keyof R, (props: CSVUploaderCellRenderProps) => React.ReactNode>
  >
  exampleFile?: { description: string; filePath: string }
}) => {
  const makeCol = createColumnHelper<CSVTableData<R>>()
  const [contents, setContents] = useState(props.initialContents)
  const [ignoreErrors, setIgnoreErrors] = useState(false)
  const { validRows, data, errors } = useMemo(() => {
    const contentRows = contents
      .map((row) =>
        Rec.indexedMap(props.schema.row, ([i, f]) =>
          f(row?.[props.headers.indexOf(i as keyof R & string)] ?? "")
        )
      )
      .map((ro) => ro as { [key in keyof R]: Validation<string, R[key]> })
      .map(Validation.Record.sequence<string, R>)
      .map((x, i) => {
        const packed = x.bimap(
          (error) => ({ rowIndex: i, error }),
          (value) =>
            props.schema.reassemble(value).bimap(
              (wholeRowError) => ({ rowIndex: i, wholeRowError }),
              (finalized) => ({ rowIndex: i, value: finalized })
            )
        )
        return packed.flat().mapFst((err) =>
          annotate<{
            rowIndex: number
            error?: Partial<Record<keyof R, string>>
            wholeRowError?: string[]
          }>(err)
        )
      })
    const [e, v] = filterValidation(contentRows)
    const d = contents.flatMap((row, rowIndex) => {
      const errorRow = e.find((err) => err.rowIndex === rowIndex)
      const rowError = errorRow?.wholeRowError
      return {
        rowError,
        errorRow: !!errorRow,
        errorsDescription: Object.values(errorRow?.error ?? {}).join(", "),
        rowIndex,
        cellData: Object.fromEntries(
          props.headers.map((h, index) => [h, { data: row[index], error: errorRow?.error?.[h] }])
        ),
      }
    })
    return { errors: e, validRows: v, data: d }
  }, [contents, props.headers, props.schema])
  const filter = useMemo(() => errorFilter<R>(errors.length), [errors.length])

  const handleChange = useCallback(
    (value: string, rowIndex: number, header: keyof R & string) => {
      setContents((prevContents) => {
        const newContents = prevContents.slice()
        const newEntry = prevContents[rowIndex].slice()
        newEntry.splice(props.headers.indexOf(header), 1, value)
        newContents.splice(rowIndex, 1, newEntry)
        return newContents
      })
    },
    [props.headers]
  )

  const handleParseCSV = (o: Blob) => {
    o.text()
      .then((t) => {
        setContents(
          parseCSV(t)
            .withDefault([])
            .flatMap((row) =>
              props.headers.every((h, index) => row[index] === h || row[index] === formatHeader(h))
                ? []
                : [row]
            )
        )
      })
      .catch(handleConsoleError)
  }

  const columns = useMemo(
    () => [
      makeCol.accessor("errorRow", {
        id: "errorRow",
        header: "Error",
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (c) =>
          c.row.original.errorRow ? (
            <DangerIcon color={Color.Danger} tooltipTitle={c.row.original.errorsDescription} />
          ) : null,
        filterFn: filter.filterFn,
      }),
      ...props.headers.map((h) =>
        makeCol.accessor(`cellData.${h}.data`, {
          id: h,
          header: formatHeader(h),
          cell: (context) => {
            const renderProps = {
              value: context.row.original.cellData[h].data,
              handleChange: (v) => handleChange(v, context.row.index, h),
              error: context.row.original.cellData[h].error,
            } satisfies CSVUploaderCellRenderProps
            return props.cellInputOverrides?.[h]?.(renderProps) ?? StringCell(renderProps)
          },
          enableGlobalFilter: true,
        })
      ),
    ],
    [filter.filterFn, handleChange, makeCol, props.cellInputOverrides, props.headers]
  )

  const handleSubmit = async () =>
    props
      .submit(validRows.map((r) => r.value))
      .then(() => notification.success({ message: "Upload successful!" }))
      .then(() => setContents(props.initialContents))
      .then(() => setIgnoreErrors(false))

  return (
    <>
      <div className="flex gap-4 items-center justify-end">
        <div className="flex flex-col items-end gap-2">
          <div className="flex gap-4 items-center ">
            {errors.length ? (
              <CheckboxWithLabel
                id="ignore-errors"
                title={`Ignore ${errors.length} ${pluralize(
                  "error",
                  errors.length
                )} and continue with upload`}
                onChange={(e) => setIgnoreErrors(e.target.checked)}
                checked={ignoreErrors}
              />
            ) : null}
            <AsyncDispatchButton
              label={`${props.buttonText(validRows.map((r) => r.value))}`}
              isDisabled={validRows.length === 0 || (!ignoreErrors && errors.length > 0)}
              onClick={handleSubmit}
              onSuccessProps={{
                isDisabled: true,
                label: "Submission successful",
              }}
              onClickedProps={{
                isDisabled: true,
                label: "Submitting...",
                leftIcon: <Spinner size="xxs" />,
              }}
              size="xlarge"
            />
          </div>

          {props.buttonSubtext ? (
            <Typography
              size={Size.Small}
              text={props.buttonSubtext(validRows.map((r) => r.value))}
            />
          ) : null}
        </div>
      </div>
      {data.length > 0 ? (
        <Table<CSVTableData<R>>
          columns={columns}
          data={data}
          globalFilter={{ placeholder: "Search records" }}
          filterBarComponents={[filter.filterBarComponent]}
          variant="grid"
          isPaginationEnabled={false}
        />
      ) : (
        <div className="w-full flex flex-col gap-4 items-center">
          <Upload
            accept="text/csv"
            name="Upload CSV"
            fileList={[]}
            customRequest={(o) => {
              if (o.file instanceof Blob) {
                handleParseCSV(o.file)
              } else {
                console.warn(o)
              }
            }}
          >
            <div className="border-2 border-dashed rounded-lg bg-neutral-100 border-neutral-600 p-12 w-full flex flex-col gap-4">
              <Button label="Upload CSV" size="large" leftIcon={<UploadIcon />} />
              <Typography text="Click or drag CSV file to upload" color={Color.Subtitle} />
              {props.exampleFile ? (
                <Card>
                  <div className="flex flex-col gap-4">
                    <div>
                      <Typography color={Color.Primary} text={props.exampleFile.description} />
                    </div>
                    <StopClickPropagation>
                      <LinkButton
                        download
                        href={props.exampleFile.filePath}
                        text="Download Example File"
                      />
                    </StopClickPropagation>
                  </div>
                </Card>
              ) : null}
            </div>
          </Upload>
        </div>
      )}
    </>
  )
}
