import { useModal } from "@components/layout/Modal"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import CompanyAutocompletePill from "@components/companies/select/CompanyAutocompletePill"
import { DangerIcon } from "@stories/icons/DangerIcon"
import { CSVUploaderCellRenderProps } from "../CSVUploadTable"

export const CompanyNameCell = ({ value, handleChange, error }: CSVUploaderCellRenderProps) => {
  const { open, closeModal, openModal } = useModal()
  return (
    <>
      {open ? (
        <div className=" min-w-36">
          <CompanyAutocompletePill
            showDropDown
            handleSelect={(v) => {
              if (!v) return
              handleChange(v.name)
              closeModal()
            }}
            selected={undefined}
            autoFocus
          />
        </div>
      ) : (
        <div
          onClick={openModal}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => (e.code === "Enter" ? openModal() : null)}
        >
          <Typography text={value} size={Size.Small} />
          {error ? (
            <div className="z-10 absolute right-2 top-2">
              <DangerIcon color={Color.Danger} tooltipTitle={error} />
            </div>
          ) : null}
        </div>
      )}
    </>
  )
}
