import { CSVUploaderCellRenderProps } from "../CSVUploadTable"
import { useCRMContacts } from "src/pages/CRM/Providers/CRMContactsProvider"
import { BaseCell } from "./StringCell"
import { DangerIcon } from "@stories/icons/DangerIcon"
import { Color } from "@stories/components/Typography/Typography"
import { InfoIcon } from "@stories/icons/InfoIcon"

export const FirmNameCell = (p: CSVUploaderCellRenderProps) => {
  const { findContactByTagName } = useCRMContacts()
  const contact = findContactByTagName(`firm_${p.value}`)
  return BaseCell({
    value: p.value,
    handleChange: p.handleChange,
    icon: p.error ? (
      <DangerIcon color={Color.Danger} tooltipTitle={p.error} />
    ) : contact ? (
      <InfoIcon
        color={Color.Primary}
        tooltipTitle="Contact will be added to existing firm of the same name."
      />
    ) : null,
  })
}
