import { LabelValueList } from "@stories/components/LabelValueList/LabelValueList"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { DealCRMContact, getCRMContactEmail } from "common/model/DealCRM/DealCRMContact"
import { DealCRMIndividualContact } from "common/model/DealCRM/DealCRMIndividualContact"
import { useMemo } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import { updateIndividualContactFirm } from "src/firebase/crm"
import { SelectContactButton } from "../../../Components/SelectContactButton"
import { useCRMContacts } from "../../../Providers/CRMContactsProvider"
import CRMContactCopyEmail from "../../ContactDisplay/ContactCopyEmail"
import { CRMContactName } from "../CRMContactName"

export const IndividualFirmAssociates = ({ contact }: { contact: DealCRMIndividualContact }) => {
  const { findContactById } = useCRMContacts()
  const db = useFirebaseWriter()

  const firmContact = contact.firm ? findContactById(contact.firm.id) : undefined

  const { contacts: allCRMContacts } = useCRMContacts()

  const associateContacts = useMemo(
    () =>
      allCRMContacts.filter(
        (c) => c.tag !== "firm" && c.firm && c.firm.id === contact.firm?.id && c.id !== contact.id
      ),
    [allCRMContacts, contact.firm?.id, contact.id]
  )

  const handleAddContactToFirm = async (c: DealCRMContact) => {
    if (c.tag === "individual" && contact.firm) {
      await updateIndividualContactFirm({ db, contact: c, firm: contact.firm })
    }
  }

  if (!firmContact || !associateContacts.length) {
    return (
      <>
        <Typography color={Color.Primary} weight={Weight.Semibold} text="Related Contacts" />
        <Typography color={Color.Neutral700} size={Size.Small} text="No related contacts found." />
      </>
    )
  }

  return (
    <div className="flex flex-col gap-2">
      <LabelValueList
        title={`Associates ${contact.firm ? `at ${contact.firm.name}` : ""}`}
        actions={
          firmContact ? (
            <SelectContactButton
              sourceContactTag="firm"
              onContactCreated={async (_) => {}}
              onContactSelected={handleAddContactToFirm}
              sourceContact={firmContact}
              hideFirms
              contactFilter={(c) =>
                (c.tag === "individual" || c.tag === "broker") && c.firm === null
              }
            />
          ) : null
        }
        items={associateContacts.map((associate) => ({
          label: <CRMContactName contact={associate} />,
          value: (
            <CRMContactCopyEmail
              textSize={Size.Small}
              email={getCRMContactEmail(associate) ?? ""}
            />
          ),
          id: associate.id,
        }))}
      />
    </div>
  )
}
