import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import { History } from "history"
import { authRoutes } from "src/Routes/auth/authRoutes"
import { getRoutePathTemplate } from "../Routes/utils"
import { config } from "../config"
import { trackEvent } from "./Tracking"

export const initSentry = (history: History) => {
  if (config.sentryKey) {
    Sentry.init({
      dsn: config.sentryKey,
      release: config.releaseVersion,
      beforeBreadcrumb: (breadcrumb) => {
        if (
          breadcrumb.category === "console" &&
          ["log", "warning", "info"].includes(breadcrumb.level || "")
        ) {
          return null
        }

        return breadcrumb
      },
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(
            history,
            authRoutes.map((route) => ({ path: getRoutePathTemplate(route.route) }))
          ),
        }),
        {
          name: "heap-error-tracking",
          setupOnce(addGlobalEventProcessor, _getCurrentHub) {
            addGlobalEventProcessor(heapTrackSentryError)
          },
        },
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.2,
      ignoreErrors: [
        /Could not find company with airtable id/,
        /Failed to fetch dynamically imported module/,
        /Failed to fetch$/,
        // Probably Microsoft Outlook crawling email links
        // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
        "Non-Error promise rejection captured with value: Object Not Found Matching Id:",
      ],
      maxValueLength: 1000,
    })
  }
}

const heapTrackSentryError = (event: Sentry.Event) => {
  const eventExceptionText = (event.exception?.values || [])
    .map((v) => v.value || "")
    .join(" ")
    .slice(0, 1000)

  const ignoredErrors = ["ResizeObserver loop limit exceeded", "TypeError: Failed to fetch"]

  if (
    event.type !== "transaction" &&
    eventExceptionText &&
    !ignoredErrors.some((err) => eventExceptionText.includes(err))
  )
    trackEvent("error", {
      message: event.message ? event.message.slice(0, 1000) : undefined,
      severity: event.level,
      type: event.type,
      exception: (event.exception?.values || [])
        .map((v) => v.value || "")
        .join(" ")
        .slice(0, 1000),
    })
  return event
}
