import { LabelValueList } from "@stories/components/LabelValueList/LabelValueList"
import { Size } from "@stories/components/Typography/Typography"
import { DealCRMBrokerContact } from "common/model/DealCRM/DealCRMBrokerContact"
import { getCRMContactEmail } from "common/model/DealCRM/DealCRMContact"
import CRMContactCopyEmail from "../../ContactDisplay/ContactCopyEmail"
import { CRMContactName } from "../CRMContactName"
import { useCRMContacts } from "src/pages/CRM/Providers/CRMContactsProvider"

export const BrokerAssociates = ({ contact }: { contact: DealCRMBrokerContact }) => {
  const { contacts: allCRMContacts } = useCRMContacts()

  const associateContacts = allCRMContacts.filter(
    (crmContact) =>
      crmContact.tag !== "firm" &&
      crmContact.firm &&
      crmContact.firm.id === contact.firm?.id &&
      crmContact.id !== contact.id
  )

  return (
    <div className="flex flex-col gap-2">
      <LabelValueList
        title={`Associates ${contact.firm ? `at ${contact.firm.name}` : ""}`}
        items={associateContacts.map((associate) => ({
          label: <CRMContactName contact={associate} />,
          value: (
            <CRMContactCopyEmail
              textSize={Size.Small}
              email={getCRMContactEmail(associate) ?? ""}
            />
          ),
          id: associate.id,
        }))}
      />
    </div>
  )
}
