import { DatePicker } from "@stories/components/Antd"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import { DealCRMPriority } from "common/model/DealCRM/Deal/DealCRMDeal"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { articulatedDateTimeFormat, shortDateFormat, sortByDate } from "common/utils/dateUtils"
import moment from "moment"
import { UpdateContactDeprecatedProps } from "src/firebase/crm"
import { PriorityDisplay } from "../../Components/PriorityDisplay/PriorityDisplay"
import { DeleteContactButton } from "./DeleteContactButton"
import { ContactOwnerDisplay } from "./ContactOwnerDisplay"
import { EditIcon } from "@stories/icons/EditIcon"

export const ContactTitle = ({
  contact,
  updateContact,
}: {
  contact: DealCRMContact
  updateContact: (p: UpdateContactDeprecatedProps<DealCRMContact>) => void
}) => {
  const mostRecentUpdate = contact.updates
    ? contact.updates.sort(sortByDate("updatedAt", "desc"))?.[0]
    : undefined

  const handlePriorityChange = (priority: DealCRMPriority) =>
    new Promise<void>((resolve) => {
      updateContact({
        contact,
        field: "priority",
        fieldValue: priority,
      })
      resolve()
    })

  return (
    <div className="flex gap-4 w-full">
      <div className="flex flex-col gap-2 w-full">
        <div className="flex justify-between items-center w-full">
          <div className="flex gap-4 items-center">
            <div className="flex items-center gap-2">
              <Typography
                text="Last contacted"
                size={Size.Small}
                shouldWrap={false}
                color={Color.Subtitle}
              />
              <DatePicker
                size="large"
                className="w-full pl-0 pr-0"
                clearIcon={false}
                disabledDate={(d) => moment().isBefore(d)}
                onChange={(v) =>
                  v
                    ? updateContact({
                        contact,
                        field: "lastContactedAt",
                        fieldValue: v.toDate(),
                      })
                    : undefined
                }
                value={contact.lastContactedAt ? moment(contact.lastContactedAt) : undefined}
              />
            </div>
            <div className="flex items-center gap-2">
              <Typography
                text="Priority"
                size={Size.Small}
                shouldWrap={false}
                color={Color.Subtitle}
              />
              <PriorityDisplay
                editable
                priority={contact.priority ?? "none"}
                onChange={handlePriorityChange}
                noneOptionDisplayOverride={<EditIcon color={Color.Link} size="xs" />}
              />
            </div>
            <div className="flex items-center gap-2">
              <Typography
                text="Owner"
                size={Size.Small}
                shouldWrap={false}
                color={Color.Subtitle}
              />
              <ContactOwnerDisplay contact={contact} />
            </div>
          </div>
          <DeleteContactButton contact={contact} />
        </div>
        <div className="flex gap-4">
          {contact.createdBy && (
            <Typography
              text={`Added by ${contact.createdBy.firstName} ${
                contact.createdBy.lastName
              } (${shortDateFormat(contact.createdAt)})`}
              size={Size.Small}
              shouldWrap={false}
              color={Color.Subtitle}
            />
          )}
          {mostRecentUpdate && (
            <Typography
              text={`Last updated by ${mostRecentUpdate.updatedBy.firstName} ${
                mostRecentUpdate.updatedBy.lastName
              } (${articulatedDateTimeFormat(mostRecentUpdate.updatedAt).date} at ${
                articulatedDateTimeFormat(mostRecentUpdate.updatedAt).time
              })`}
              size={Size.Small}
              shouldWrap={false}
              color={Color.Subtitle}
            />
          )}
        </div>
      </div>
    </div>
  )
}
