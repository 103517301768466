import CompanyLogoAndName from "@components/CompanyLogoAndName"
import { formatInt } from "@components/displays/numeric/Currency"
import DropdownMenu, { DropdownMenuItem } from "@stories/components/DropdownMenu/DropdownMenu"
import { NumberField } from "@stories/components/Inputs/NumberInput/NumberField"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { CrossIcon } from "@stories/icons/CrossIcon"
import { DotsIcon } from "@stories/icons/DotsIcon"
import { Just, Maybe, Nothing, nullableToMaybe } from "common/containers/Maybe"
import { viewAccountIdFields } from "common/model/Account"
import { CompanyIdFields, viewCompanyIdFields } from "common/model/Company"
import { Structure } from "common/model/data-product/pricing/TransactionStructure"
import { DealCRMContact, viewDealCRMContactIdFields } from "common/model/DealCRM/DealCRMContact"
import {
  ContactHolding,
  GrantHolding,
  Holding,
  InvestmentHolding,
  mergeHoldingsByCompany,
  numExercisedShares,
  ShareClass,
  ShareHolding,
  totalHoldingShares,
} from "common/model/holdings/Holding"
import { User, viewUserIdFields } from "common/model/User"
import { isLoaded } from "common/utils/Loading"
import {
  formatAbbreviatedCurrency,
  formatAbbreviatedNumber,
  formatRoundedAbbreviatedCurrency,
  formatShares,
} from "common/utils/math/format"
import { undefinedsToNulls } from "common/utils/ObjectUtils"
import { capitalize, uniq } from "lodash"
import { useFirebaseWriter } from "src/firebase/Context"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { SelectField } from "src/pages/CRM/Components/DealParticipantFieldDisplay/DealParticipantFieldDisplay"
import { useContactHoldings } from "src/pages/CRM/Providers/ContactHoldingsProvider"
import { useCompanies } from "src/providers/data/CompanyProvider"
import { handleConsoleError, trackEvent, trackEventInFirestoreAndHeap } from "src/utils/Tracking"
import { v4 as uuidv4 } from "uuid"
import { useCurrentUser } from "../../../../../providers/currentUser/useCurrentUser"
import { AddCompanyRow } from "../BuyInterest/AddCompanyRow"
import { CopyIcon } from "@stories/icons/CopyIcon"
import { BrokerCartaLink } from "src/pages/CRM/Components/CartaShareVerification/BrokerCartaLink"
import { PercentageField } from "@stories/components/Inputs/NumberInput/PercentageField"
import { NotesColumn } from "src/pages/CRM/Deals/NotesColumn"
import { holdingToDealCRMNoteHoldingSource } from "common/model/DealCRM/DealCRMNoteSourceConverters"
import { PoweredByCarta } from "src/pages/Holdings/carta/PoweredByCarta"

type HoldingsHeaders =
  | "Company"
  | "Source"
  | "Notes"
  | "Type"
  | "Value ($)"
  | "Share Count"
  | "Vested Shares"
  | "Exercised Shares"
  | "Share Class"
  | "Ownership Structure"
  | "SPV Carry"
  | "Management Fee"
  | ""

export const HoldingsTableAndLink = ({ contact }: { contact: DealCRMContact }) => {
  const user = useCurrentUser()
  const holdings = useContactHoldings()

  if (!isLoaded(user) || !isLoaded(holdings)) return null

  const haveCartaHoldingsBeenImported =
    holdings &&
    holdings.some(
      (holding) => holding.dataSource === "carta" && holding.shareholder.contact.id === contact.id
    )

  return (
    <div className="flex flex-col gap-4">
      {!haveCartaHoldingsBeenImported && contact.tag === "individual" && <BrokerCartaLink />}
      <HoldingsTableInner
        contact={contact}
        user={user.user}
        holdings={holdings.filter((holding) => holding.shareholder.contact.id === contact.id)}
      />
    </div>
  )
}


export const HoldingsTableInner = ({
  contact,
  user,
  holdings,
}: {
  contact: DealCRMContact
  user: User
  holdings: ContactHolding[]
}) => {
  const db = useFirebaseWriter()
  const firebase9 = useFirebase9()
  const companies = useCompanies(uniq(holdings.map((h) => h.company.id)))

  if (!isLoaded(companies)) return null

  const headers: HoldingsHeaders[] = [
    "Company",
    "Source",
    "Notes",
    "Type",
    "Value ($)",
    "Share Count",
    "Vested Shares",
    "Exercised Shares",
    "Share Class",
    "Ownership Structure",
    "Management Fee",
    "SPV Carry",
    "",
  ]

  const handleAddHolding = (company: CompanyIdFields) => {
    const id = uuidv4()
    const holding: Holding = {
      id,
      account: viewAccountIdFields(user.account),
      company: viewCompanyIdFields(company),
      dataSource: "crm",
      grants: {},
      investments: {},
      shareholder: {
        tag: "crm_contact",
        contact: viewDealCRMContactIdFields(contact),
      },
      updatedAt: new Date(),
      createdBy: viewUserIdFields(user),
      createdAt: new Date(),
    }
    db.createHolding(holding)
      .then(() =>
        trackEventInFirestoreAndHeap(firebase9, user, "crm-contact-holding-added", {
          companyId: holding.company.id,
          companyName: holding.company.name,
        })
      ) // TODO: track contact update in contact update log
      .catch(handleConsoleError)
  }

  const handleRemoveHolding = (holding: ContactHolding) => {
    db.deleteHolding(holding.id)
      .then(() =>
        trackEvent("crm-contact-holding-deleted", {
          companyId: holding.company.id,
          companyName: holding.company.name,
        })
      )
      .catch(handleConsoleError)
  }

  const updateContactHolding = async (
    holding: ContactHolding,
    field: HoldingsHeaders,
    value: unknown
  ) => {
    const holdingUpdate = buildHoldingUpdate(holding, field, value)

    await db
      .updateHolding(holding.id, holdingUpdate)
      .then(() => {
        trackEventInFirestoreAndHeap(firebase9, user, "crm-contact-holding-updated", {
          companyId: holding.company.id,
          companyName: holding.company.name,
          field,
        })
      })
      .catch(handleConsoleError)
  }

  const holdingsSortedByCompanyName = mergeHoldingsByCompany(holdings).sort((a, b) =>
    a.company.name.localeCompare(b.company.name)
  )

  const getHoldingType = (holding: ContactHolding) => {
    const grants = Object.values(holding.grants)
    const investments = Object.values(holding.investments)
    if (grants.length) {
      const grantTypes = uniq(
        grants.filter((grant) => grant.grantType).map((grant) => grant.grantType)
      )
      return grantTypes.join(", ")
    } else if (investments.length) {
      const investmentTypes = uniq(
        investments
          .filter((investment) => investment.assetType)
          .map((investment) => investment.assetType)
      )
      return investmentTypes.join(", ")
    }
    return ""
  }

  const onlyHasCartaHoldings = holdings.every((h) => h.dataSource === "carta")

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row items-center gap-4">
        <Typography color={Color.Primary} weight={Weight.Semibold} text="Holdings" />
        {onlyHasCartaHoldings && <PoweredByCarta />}
      </div>
      <div className="w-full overflow-x-auto border rounded">
        <table className="table-auto w-full">
          <thead className="bg-neutral-300">
            <tr className="h-8">
              {headers.map((header) => (
                <th className="whitespace-nowrap px-2 text-left" key={header}>
                  <div className="flex gap-2 items-center">{header}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {holdingsSortedByCompanyName.map((holding) => {
              const company = companies.find((c) => c.id === holding.company.id)
              if (!company) return null
              const sharePrice: number | undefined =
                company.priceEstimatesSummary?.currentPrice?.priceEstimatePPS ??
                company.fundingRoundsSummary?.lastKnownPPS?.pps ??
                undefined
              const isGrant = Object.values(holding.grants).length
              const isInvestment = Object.values(holding.investments).length
              const grant: Maybe<GrantHolding> = isGrant
                ? Just(Object.values(holding.grants)[0])
                : Nothing
              const investment: Maybe<InvestmentHolding> = isInvestment
                ? Just(Object.values(holding.investments)[0])
                : Nothing
              const numShares = grant
                .map((g) => g.numShares)
                .or(investment.map((i) => i.estimatedNumShares))
                .or(nullableToMaybe(holding.summary?.numShares))
                .withDefault(undefined)
              const dollarAmount = grant
                .map((g) => g.estimatedValue)
                .or(investment.map((i) => i.estimatedValue))
                .or(nullableToMaybe(holding.summary?.dollarAmount))
                .withDefault(undefined)
              const shareClass = grant
                .map((g) => g.shareClass)
                .or(investment.map((i) => i.shareClass))
                .or(nullableToMaybe(holding.summary?.shareClass))
                .withDefault(undefined)
              const ownershipStructure = grant
                .map((g) => g.ownershipStructure)
                .or(investment.map((i) => i.ownershipStructure))
                .or(nullableToMaybe(holding.summary?.ownershipStructure))
                .withDefault(undefined)

              const hasOptions = Object.values(holding.grants).some(
                (g) => g.grantType === "options"
              )

              return (
                <tr key={holding.id} className="border-b last:border-b-0 h-8 relative">
                  <td className="whitespace-nowrap px-2 border-r cursor-pointer">
                    <CompanyLogoAndName company={holding.company} size="xs" disableClick />
                  </td>
                  <td className="whitespace-nowrap border-r pl-2">
                    <Typography
                      size={Size.XSmall}
                      text={holding.dataSource === "carta" ? "Carta" : ""}
                    />
                  </td>
                  <td className="whitespace-nowrap border-r pl-2">
                    <NotesColumn
                      accountId={user.account.id}
                      source={holdingToDealCRMNoteHoldingSource(holding)}
                    />
                  </td>
                  <td className="whitespace-nowrap border-r pl-2">
                    <Typography size={Size.XSmall} text={getHoldingType(holding)} />
                  </td>
                  <td className="whitespace-nowrap border-r cursor-pointer">
                    <NumberField
                      noBorder
                      value={dollarAmount}
                      onChange={(updatedDollarAmount) =>
                        updateContactHolding(holding, "Value ($)", updatedDollarAmount)
                      }
                      formatter={(v) => `${formatAbbreviatedCurrency(v)}`}
                      placeholderClassName="placeholder-neutral-700"
                      placeholder={
                        sharePrice && numShares
                          ? `${formatRoundedAbbreviatedCurrency(sharePrice * numShares, 0)} (est)`
                          : ""
                      }
                    />
                  </td>
                  <td className="whitespace-nowrap border-r cursor-pointer">
                    <NumberField
                      noBorder
                      value={numShares}
                      onChange={(updatedNumShares) =>
                        updateContactHolding(holding, "Share Count", updatedNumShares)
                      }
                      formatter={(v) => `${formatInt(v)}`}
                      placeholderClassName="placeholder-neutral-700"
                      placeholder={
                        sharePrice && dollarAmount
                          ? `${formatAbbreviatedNumber(dollarAmount / sharePrice, 0)} (est)`
                          : ""
                      }
                    />
                  </td>
                  <td className="whitespace-nowrap border-r pl-2">
                    {isGrant && (
                      <Typography
                        size={Size.XSmall}
                        text={totalHoldingShares(holding, {
                          includeUnvested: false,
                        })
                          .map(formatShares)
                          .withDefault("")}
                      />
                    )}
                  </td>
                  <td className="whitespace-nowrap border-r pl-2">
                    {isGrant && hasOptions && (
                      <Typography
                        size={Size.XSmall}
                        text={formatShares(numExercisedShares(holding))}
                      />
                    )}
                  </td>
                  <td className="whitespace-nowrap px-2 border-r cursor-pointer">
                    <SelectField<Exclude<ShareHolding["shareClass"], undefined> | null>
                      size="small"
                      value={shareClass ?? null}
                      options={["common", "preferred"]}
                      renderValue={(v) => (
                        <Typography size={Size.XSmall} text={v ? capitalize(v) : ""} />
                      )}
                      handleChange={(updatedShareClass) =>
                        updateContactHolding(holding, "Share Class", updatedShareClass)
                      }
                      editable
                    />
                  </td>
                  <td className="whitespace-nowrap px-2 border-r cursor-pointer">
                    <SelectField<Exclude<ShareHolding["ownershipStructure"], undefined> | null>
                      size="small"
                      value={ownershipStructure ?? null}
                      options={["direct", "indirect", "unknown"]}
                      renderValue={(v) => (
                        <Typography
                          size={Size.XSmall}
                          text={v === "direct" ? "Direct" : v === "indirect" ? "Fund" : "Other"}
                        />
                      )}
                      handleChange={(updatedOwnershipStructure) =>
                        updateContactHolding(
                          holding,
                          "Ownership Structure",
                          updatedOwnershipStructure
                        )
                      }
                      editable
                    />
                  </td>
                  <td className="whitespace-nowrap border-r cursor-pointer">
                    <PercentageField
                      noBorder
                      value={holding.summary?.spvManagementFee}
                      onChange={(updatedSPVManagementFee) =>
                        updateContactHolding(holding, "Management Fee", updatedSPVManagementFee)
                      }
                    />
                  </td>
                  <td className="whitespace-nowrap border-r cursor-pointer">
                    <PercentageField
                      noBorder
                      value={holding.summary?.spvCarry}
                      onChange={(updatedSPVCarry) =>
                        updateContactHolding(holding, "SPV Carry", updatedSPVCarry)
                      }
                    />
                  </td>
                  <td className="whitespace-nowrap px-2 cursor-pointer">
                    <DropdownMenu
                      menuButtonIcon={<DotsIcon color={Color.Black} />}
                      menuItems={
                        [
                          {
                            icon: <CrossIcon />,
                            id: "0",
                            label: "Remove",
                            onClick: () => handleRemoveHolding(holding),
                            variant: "danger",
                            heapName: "remove-contact-holdings-button",
                          },
                          // currentUser.isAdmin || config.env === "dev"
                          {
                            icon: <CopyIcon />,
                            id: "1",
                            label: "Copy Id",
                            onClick: () => {
                              navigator.clipboard.writeText(holding.id)
                            },
                          },
                        ].filter(Boolean) as DropdownMenuItem[]
                      }
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <AddCompanyRow buttonLabel="Add Holding" onAddCompany={handleAddHolding} />
      </div>
    </div>
  )
}

const buildHoldingUpdate = (
  holding: ContactHolding,
  field: HoldingsHeaders,
  value: unknown
): Partial<ContactHolding> => {
  let holdingUpdate: Partial<ContactHolding> = {}

  if (Object.keys(holding.grants).length) {
    const grantId = Object.keys(holding.grants)[0]
    const updatedGrant: GrantHolding = {
      ...holding.grants[0],
    }

    switch (field) {
      case "Value ($)":
        updatedGrant.estimatedValue = value as number
        updatedGrant.numShares = undefined
        break
      case "Ownership Structure":
        updatedGrant.ownershipStructure = value as Structure
        break
      case "Share Class":
        updatedGrant.shareClass = value as ShareClass
        break
      case "Share Count":
        updatedGrant.numShares = value as number
        updatedGrant.estimatedValue = undefined
        break
      case "SPV Carry":
        holding.summary = {
          ...holding.summary,
          spvCarry: value as number,
        }
        break
      case "Management Fee":
        holding.summary = {
          ...holding.summary,
          spvManagementFee: value as number,
        }
        break
      default:
        throw new Error("Unsupported holding field updated")
    }
    holdingUpdate = {
      grants: {
        [grantId]: updatedGrant,
      },
    }
  } else if (Object.keys(holding.investments).length) {
    const investmentId = Object.keys(holding.investments)[0]
    const investment = holding.investments[investmentId]
    switch (field) {
      case "Value ($)":
        investment.estimatedValue = value as number
        investment.estimatedNumShares = undefined
        break
      case "Share Count":
        investment.estimatedNumShares = value as number
        investment.estimatedValue = undefined
        break
      case "Share Class":
        investment.shareClass = value as ShareClass
        break
      case "Ownership Structure":
        investment.ownershipStructure = value as Structure
        break
      case "SPV Carry":
        holding.summary = {
          ...holding.summary,
          spvCarry: value as number,
        }
        break
      case "Management Fee":
        holding.summary = {
          ...holding.summary,
          spvManagementFee: value as number,
        }
        break
      default:
        throw new Error("Unsupported holding field updated")
    }
    holdingUpdate = {
      investments: {
        [investmentId]: investment,
      },
    }
  } else {
    holdingUpdate.summary = { ...(holding.summary ?? {}) }
    switch (field) {
      case "Value ($)":
        holdingUpdate.summary.dollarAmount = value as number
        holdingUpdate.summary.numShares = undefined
        break
      case "Share Count":
        holdingUpdate.summary.numShares = value as number
        holdingUpdate.summary.dollarAmount = undefined
        break
      case "Share Class":
        holdingUpdate.summary.shareClass = value as ShareClass
        break
      case "Ownership Structure":
        holdingUpdate.summary.ownershipStructure = value as Structure
        break
      case "SPV Carry":
        holding.summary = {
          ...holding.summary,
          spvCarry: value as number,
        }
        break
      case "Management Fee":
        holding.summary = {
          ...holding.summary,
          spvManagementFee: value as number,
        }
        break
      default:
        throw new Error("Unsupported holding field updated")
    }
  }

  return undefinedsToNulls(holdingUpdate)
}
