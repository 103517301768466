import YesNoNullDropdown from "@components/inputs/YesNoNullDropdown"
import { PercentageField } from "@stories/components/Inputs/NumberInput/PercentageField"
import { isIntermediaryAccount } from "common/model/Account"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { isLoaded } from "common/utils/Loading"
import { isNil } from "lodash"
import { UpdateContactProps } from "src/firebase/crm"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import ContactSectorFocusList from "../ContactSectorFocusList"
import ContactInputField from "@stories/components/ContactInputField/ContactInputField"

export const InvestmentPreferences = ({
  contact,
  updateContact,
}: {
  contact: DealCRMContact
  updateContact: (params: UpdateContactProps<DealCRMContact>) => unknown
}) => {
  const account = useCurrentAccount()
  const isBrokerUser = isLoaded(account) && isIntermediaryAccount(account)

  return (
    <div className="flex-1 flex flex-col">
      <ContactInputField
        label="Typical Commission"
        value={
          contact.typicalBrokerCommissionPercent
            ? `${contact.typicalBrokerCommissionPercent * 100}%`
            : ""
        }
        error={false}
        variant="input"
      >
        <PercentageField
          autoFocus
          name="typicalCommission"
          value={contact.typicalBrokerCommissionPercent}
          max={0.1}
          onChange={(newCommission) =>
            updateContact({
              contact,
              update: {
                typicalBrokerCommissionPercent: newCommission,
              },
            })
          }
        />
      </ContactInputField>

      <ContactInputField
        label="Cash on Hand"
        value={contact.investmentPreferences?.cashOnHand}
        error={false}
        variant="select"
      >
        <YesNoNullDropdown
          autoFocus
          value={contact.investmentPreferences?.cashOnHand ?? null}
          onChange={(newValue) =>
            updateContact({
              contact,
              update: {
                investmentPreferences: {
                  ...contact.investmentPreferences,
                  cashOnHand: newValue,
                },
              },
            })
          }
        />
      </ContactInputField>

      <ContactInputField
        label="Open to SPVs"
        value={contact.investmentPreferences?.openToSPVs}
        error={false}
        variant="select"
      >
        <YesNoNullDropdown
          autoFocus
          value={contact.investmentPreferences?.openToSPVs ?? null}
          onChange={(newValue) =>
            updateContact({
              contact,
              update: {
                investmentPreferences: {
                  ...contact.investmentPreferences,
                  openToSPVs: newValue,
                },
              },
            })
          }
        />
      </ContactInputField>

      <ContactInputField
        label="Open to Dual Layer SPV"
        value={contact.investmentPreferences?.openToDoubleLayerSPVs}
        error={false}
        variant="select"
      >
        <YesNoNullDropdown
          autoFocus
          value={contact.investmentPreferences?.openToDoubleLayerSPVs ?? null}
          onChange={(newValue) =>
            updateContact({
              contact,
              update: {
                investmentPreferences: {
                  ...contact.investmentPreferences,
                  openToDoubleLayerSPVs: newValue,
                },
              },
            })
          }
        />
      </ContactInputField>

      {isBrokerUser && (
        <>
          <ContactInputField
            label="Max Management Fee"
            value={
              contact.investmentPreferences?.maxManagementFee
                ? `${contact.investmentPreferences.maxManagementFee * 100}%`
                : ""
            }
            error={
              !!contact.investmentPreferences?.maxManagementFee &&
              contact.investmentPreferences.maxManagementFee > 0.05
            }
            variant="input"
          >
            <PercentageField
              autoFocus
              name="investmentPreferences.maxManagementFee"
              value={contact.investmentPreferences?.maxManagementFee}
              max={0.05}
              onChange={(fee) =>
                updateContact({
                  contact,
                  update: {
                    investmentPreferences: {
                      ...contact.investmentPreferences,
                      maxManagementFee: isNil(fee) ? null : fee,
                    },
                  },
                })
              }
            />
          </ContactInputField>
          <ContactInputField
            label="Max Management Carry"
            value={
              contact.investmentPreferences?.maxCarry
                ? `${contact.investmentPreferences.maxCarry * 100}%`
                : ""
            }
            error={
              !!contact.investmentPreferences?.maxCarry &&
              contact.investmentPreferences.maxCarry > 0.3
            }
            variant="input"
          >
            <PercentageField
              autoFocus
              name="investmentPreferences.maxCarry"
              value={contact.investmentPreferences?.maxCarry}
              max={0.3}
              onChange={(maxCarry) =>
                updateContact({
                  contact,
                  update: {
                    investmentPreferences: {
                      ...contact.investmentPreferences,
                      maxCarry: isNil(maxCarry) ? null : maxCarry,
                    },
                  },
                })
              }
            />
          </ContactInputField>
          <ContactInputField
            label="Open to Forwards"
            value={contact.investmentPreferences?.openToForwards}
            error={false}
            variant="select"
          >
            <YesNoNullDropdown
              autoFocus
              value={contact.investmentPreferences?.openToForwards ?? null}
              onChange={(newValue) =>
                updateContact({
                  contact,
                  update: {
                    investmentPreferences: {
                      ...contact.investmentPreferences,
                      openToForwards: newValue,
                    },
                  },
                })
              }
            />
          </ContactInputField>
        </>
      )}

      <ContactInputField
        label="Require Diligence"
        value={contact.investmentPreferences?.requiresDiligence}
        error={false}
        variant="select"
      >
        <YesNoNullDropdown
          autoFocus
          value={contact.investmentPreferences?.requiresDiligence ?? null}
          onChange={(newValue) =>
            updateContact({
              contact,
              update: {
                investmentPreferences: {
                  ...contact.investmentPreferences,
                  requiresDiligence: newValue,
                },
              },
            })
          }
        />
      </ContactInputField>

      {isBrokerUser && (
        <>
          <ContactInputField
            label="Pays Brokers Fees"
            value={contact.investmentPreferences?.paysBrokerFees}
            error={false}
            variant="select"
          >
            <YesNoNullDropdown
              autoFocus
              value={contact.investmentPreferences?.paysBrokerFees ?? null}
              onChange={(newValue) =>
                updateContact({
                  contact,
                  update: {
                    investmentPreferences: {
                      ...contact.investmentPreferences,
                      paysBrokerFees: newValue,
                    },
                  },
                })
              }
            />
          </ContactInputField>
        </>
      )}
      <ContactInputField
        label="Sector Focus"
        value={contact.sectorsOfInterest}
        error={false}
        variant="select"
        saveMethod="onSave"
      >
        <ContactSectorFocusList contact={contact} updateContact={updateContact} />
      </ContactInputField>
    </div>
  )
}
