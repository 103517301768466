import Spinner from "@components/icons/Spinner"
import { Company } from "common/model/Company"
import * as React from "react"
import { Suspense } from "react"
import { Route, Routes } from "react-router-dom"
import { AdminAllCompaniesProvider } from "src/providers/companies/useAllCompanies"
import { namedImport } from "src/utils/importUtils"
import { Routes as AppRoutes } from "../Routes/Routes"
import { useLoggedInUser } from "../providers/loggedInUser/useLoggedInUser"
import { customLazyLoad } from "../utils/CustomLazyLoad"
import { BasicNav } from "./BasicNav"
import { OrderObservationView } from "./components/OrderObservationView"
import { RecordInspector } from "./components/RecordInspector"
import { updateDocumentLogged } from "./components/RecordInspectorCRUD"
import { PageTitle } from "@components/typography/PageTitle"
import { TradeDataDashboard } from "./engagement/TradeDataDashboard"
import AdminLinkedBrokersPage from "./LinkedBroker/AdminLinkedBrokersPage/AdminLinkedBrokersPage"
import { TradeView } from "./components/trades/TradeView"
import { PriceObservationView } from "./PriceObservationView"
import { AdminDealDistributionList } from "./DealDistribution/AdminDealDistributionList"
import { AdminDealDistributionView } from "./DealDistribution/AdminDealDistributionView"

const Accounts = customLazyLoad(() => import("./Accounts").then(namedImport("Accounts")))
const AccessControl = customLazyLoad(() => import("./AccessControl"))
const PricingTools = customLazyLoad(() => import("./PricingTools"))
const AdminFeatureFlagsPage = customLazyLoad(() => import("./FeatureFlags/AdminFeatureFlagsPage"))
const AdminMigrationsPage = customLazyLoad(() => import("./Migrations/AdminMigrationsPage"))
const AdminInbox = customLazyLoad(() => import("../pages/Messaging/AdminInbox"))
const AdminEventFeed = customLazyLoad(() =>
  import("./Notification").then(namedImport("AdminEventFeed"))
)
const DataTools = customLazyLoad(() => import("./DataTools/DataTools"))

const DocumentTemplates = customLazyLoad(() => import("./document_templates/DocumentTemplates"))
const DataProductAdminUploadPage = customLazyLoad(() =>
  import("../pages/Data/Admin/Page").then(namedImport("DataProductAdminUploadPage"))
)
const PageNotFound = customLazyLoad(() =>
  import("../pages/PageNotFound").then(namedImport("PageNotFound"))
)
const CompanyLookup = customLazyLoad(() => import("../components/CompanyLookup"))
const AdminOrderInquiryPage = customLazyLoad(
  () => import("./OrderInquiry/AdminOrderInquiryPage/AdminOrderInquiryPage")
)
const APILog = customLazyLoad(() => import("./api-log/ApiLog").then(namedImport("APILog")))
const EmailOutbox = customLazyLoad(() => import("./email/EmailOutbox"))
const CampaignTools = customLazyLoad(() => import("./CampaignTools/CampaignTools"))
const Engagement = customLazyLoad(() => import("./engagement/AccountEngagement"))
const AdminTentativeInterestView = customLazyLoad(
  () => import("./TentativeInterest/AdminTentativeInterestView/AdminTentativeInterestView")
)

const removeAdminPrefix = (route: string) => route.replace(/^\/admin\//, "/")

const Admin: React.FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const user = useLoggedInUser()
  if (!user || !user.isAdmin) return <PageNotFound />

  const adminLinks = [
    { name: "Accounts", route: AppRoutes.admin.accounts, component: Accounts },
    { name: "Access Control", route: AppRoutes.admin.accessControl, component: AccessControl },
    {
      name: "Contributor Dashboard",
      route: AppRoutes.admin.contributorDashboard,
      component: TradeDataDashboard,
    },
    { name: "Engagement", route: AppRoutes.admin.engagement, component: Engagement },
    { name: "Campaigns", route: AppRoutes.admin.campaigns, component: CampaignTools },
    { name: "Email Outbox", route: AppRoutes.admin.emailOutbox, component: EmailOutbox },
    { name: "Pricing", route: AppRoutes.admin.pricing, component: PricingTools },
    { name: "Data", route: AppRoutes.admin.data, component: DataTools },
    {
      name: "Data Upload",
      route: AppRoutes.admin.dataUpload,
      component: DataProductAdminUploadPage,
    },
    { name: "Contracts", route: AppRoutes.admin.contracts, component: DocumentTemplates },
    { name: "Companies", route: AppRoutes.admin.companies, component: CompanyLookup },
    { name: "API Log", route: AppRoutes.admin.apiLog, component: APILog },
    { name: "Notifications", route: AppRoutes.admin.eventFeed, component: AdminEventFeed },
    {
      name: "Feature Flags",
      route: AppRoutes.admin.featureFlags,
      component: AdminFeatureFlagsPage,
    },
    { name: "Migrations", route: AppRoutes.admin.migrations, component: AdminMigrationsPage },
    { name: "Messages", route: AppRoutes.admin.inbox, component: AdminInbox },
    {
      name: "Linked Brokers",
      route: AppRoutes.admin.linkedBrokers,
      component: AdminLinkedBrokersPage,
    },
    {
      name: "CRM Deal Distro",
      route: AppRoutes.admin.dealDistributions.root,
      component: AdminDealDistributionList,
    },
  ]

  return (
    <AdminAllCompaniesProvider>
      <div className="p-4 w-full space-y-4">
        <PageTitle title="Admin" />
        <div>
          <BasicNav links={adminLinks} />
          <Suspense fallback={<Spinner size="lg" />}>
            <Routes>
              <Route path={removeAdminPrefix(AppRoutes.admin.root)} element={<Accounts />} />
              {adminLinks.map((d) => {
                const Comp = d.component
                return <Route key={d.name} path={removeAdminPrefix(d.route)} element={<Comp />} />
              })}

              <Route
                path={removeAdminPrefix(AppRoutes.admin.records.company(":companyId").root)}
                element={
                  <RecordInspector<Company>
                    editor={{
                      logoUrl: { display: null },
                      squareLogoUrl: { display: null },
                      postgresCompanyId: { display: null },
                      id: { display: null },
                    }}
                    updateDocumentWithEditLogs={updateDocumentLogged<Company>}
                    canEdit={false}
                    canDelete={false}
                  />
                }
              />
              <Route
                path={removeAdminPrefix(
                  AppRoutes.admin.records
                    .company(":companyId")
                    .priceObservations(":priceObservationId")
                )}
                element={<PriceObservationView />}
              />
              <Route
                path={removeAdminPrefix(
                  AppRoutes.admin.records.order({
                    id: ":orderId",
                    darkpool: false,
                    orderCollection: "platform",
                  })
                )}
                element={<OrderObservationView />}
              />
              <Route
                path={removeAdminPrefix(
                  AppRoutes.admin.tentativeInterest.tentativeInterestView(":tentativeInterestId")
                )}
                element={<AdminTentativeInterestView />}
              />
              <Route
                path={removeAdminPrefix(AppRoutes.admin.records.trade(":tradeId"))}
                element={<TradeView />}
              />
              <Route
                path={removeAdminPrefix(
                  AppRoutes.admin.records.order({
                    id: ":orderId",
                    darkpool: true,
                    orderCollection: "darkpool",
                  })
                )}
                element={<OrderObservationView darkpool />}
              />
              <Route
                path={removeAdminPrefix(
                  AppRoutes.admin.orderInquiries.orderInquiry(":orderInquiryId")
                )}
                element={<AdminOrderInquiryPage />}
              />
              <Route
                path={removeAdminPrefix(
                  AppRoutes.admin.dealDistributions.distribution(":distributionId")
                )}
                element={<AdminDealDistributionView />}
              />
            </Routes>
          </Suspense>
        </div>
      </div>
    </AdminAllCompaniesProvider>
  )
}

export default Admin
