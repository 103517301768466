import { Select } from "@stories/components/Antd"
import ContactInputField from "@stories/components/ContactInputField/ContactInputField"
import {
  NumberField,
  sizeScaledInputWarning,
} from "@stories/components/Inputs/NumberInput/NumberField"
import { firmTypeOptions } from "common/model/Account"
import {
  InvestmentTimeline,
  InvestmentTimelines,
} from "common/model/DealCRM/ContactInvestmentPreferences"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { DealCRMFirmContact } from "common/model/DealCRM/DealCRMFirmContact"
import { formatAbbreviatedCurrency } from "common/utils/math/format"
import { isNil } from "lodash"
import { useFirebaseWriter } from "src/firebase/Context"
import { UpdateContactProps, updateContact } from "src/firebase/crm"
import { StringField } from "src/pages/CRM/Components/InputFields/StringField"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { ContentSection } from "./ContentSection"
import { InvestmentPreferences } from "./InvestmentPreferences"

export const FirmContactDetails = ({ contact }: { contact: DealCRMFirmContact }) => {
  const db = useFirebaseWriter()
  const user = useLoggedInUser()

  const handleUpdateContact = <C extends DealCRMContact>({
    contact: updatedContact,
    update,
  }: UpdateContactProps<C>) =>
    updateContact({ db, contact: updatedContact, update, user: user.user })

  return (
    <>
      <ContentSection title={contact.isBrokerage ? "Broker Details" : "Fund Details"}>
        <ContactInputField
          label="Location"
          value={contact.location?.city}
          error={false}
          variant="input"
        >
          <StringField
            autoFocus
            placeholder="Location"
            value={contact.location?.city}
            onChange={(city) =>
              handleUpdateContact({
                contact,
                update: {
                  location: isNil(city)
                    ? {
                        city: "",
                        state: "",
                        country: "",
                      }
                    : {
                        city,
                        state: contact.location?.state ?? "",
                        country: contact.location?.country ?? "",
                      },
                },
              })
            }
          />
        </ContactInputField>
        {contact.isBrokerage ? (
          <>
            {/* TODO: Implement Company Focus */}
            {/* <ContactInputField
              label="Company Focus"
              value={contact.companyFocus}
              error={false}
              variant="input"
            >
              <ContactCompanyFocusList
                contact={contact}
                updateContact={(params) =>
                  handleUpdateContact({
                    contact: params.contact,
                    update: params.update,
                  })
                }
              />
            </ContactInputField> */}
          </>
        ) : (
          <>
            <ContactInputField
              label="Type"
              value={contact.classification}
              error={false}
              variant="select"
            >
              <Select<string>
                style={{ width: "200px" }}
                value={contact.classification}
                options={firmTypeOptions
                  .slice()
                  .sort() // change to toSorted once polyfill situation is figured out
                  .map((val) => ({
                    value: val,
                    label: val,
                  }))}
                onChange={(classification) =>
                  handleUpdateContact({
                    contact,
                    update: {
                      classification,
                    },
                  })
                }
              />
            </ContactInputField>
          </>
        )}
      </ContentSection>

      {!contact.isBrokerage && (
        <ContentSection title="Investment Preferences" divider>
          <ContactInputField
            label="Assets Under Management"
            value={contact.aum}
            error={false}
            variant="input"
          >
            <NumberField
              autoFocus
              placeholder="AUM ($)"
              value={contact.aum}
              onChange={(newAUM) =>
                handleUpdateContact({
                  contact,
                  update: {
                    aum: isNil(newAUM) ? null : newAUM,
                  },
                })
              }
              formatter={formatAbbreviatedCurrency}
              inputWarningFunction={sizeScaledInputWarning}
            />
          </ContactInputField>

          <ContactInputField
            key="targetCheckSize"
            label="Target Check Size"
            check={!!contact.targetCheckSize?.lowerBound && !!contact.targetCheckSize?.upperBound}
            value={
              !!contact.targetCheckSize?.lowerBound || !!contact.targetCheckSize?.upperBound
                ? `${
                    contact.targetCheckSize?.lowerBound
                      ? formatAbbreviatedCurrency(contact.targetCheckSize.lowerBound)
                      : "n/a"
                  } - ${
                    contact.targetCheckSize?.upperBound
                      ? formatAbbreviatedCurrency(contact.targetCheckSize.upperBound)
                      : "n/a"
                  }`
                : ""
            }
            error={
              !!sizeScaledInputWarning(contact.targetCheckSize?.lowerBound ?? undefined) ||
              !!sizeScaledInputWarning(contact.targetCheckSize?.upperBound ?? undefined)
            }
            variant="input"
          >
            <div className="flex gap-4">
              <NumberField
                size="xsmall"
                placeholder="Min"
                value={contact.targetCheckSize?.lowerBound}
                onChange={(lowerBound) =>
                  handleUpdateContact({
                    contact,
                    update: {
                      targetCheckSize: {
                        lowerBound: isNil(lowerBound) ? null : lowerBound,
                        upperBound: contact.targetCheckSize?.upperBound ?? null,
                      },
                    },
                  })
                }
                formatter={formatAbbreviatedCurrency}
                inputWarningFunction={sizeScaledInputWarning}
              />
              <span>-</span>
              <NumberField
                size="xsmall"
                placeholder="Max"
                name="maxCheckSize"
                value={contact.targetCheckSize?.upperBound}
                onChange={(upperBound) =>
                  handleUpdateContact({
                    contact,
                    update: {
                      targetCheckSize: {
                        lowerBound: contact.targetCheckSize?.lowerBound ?? null,
                        upperBound: isNil(upperBound) ? null : upperBound,
                      },
                    },
                  })
                }
                formatter={formatAbbreviatedCurrency}
                inputWarningFunction={sizeScaledInputWarning}
              />
            </div>
          </ContactInputField>

          <ContactInputField
            label="Typical Investment Timeline"
            value={contact.investmentPreferences.typicalInvestmentTimeline}
            error={false}
            variant="select"
          >
            <Select<InvestmentTimeline>
              style={{ width: "200px" }}
              value={contact.investmentPreferences.typicalInvestmentTimeline}
              options={InvestmentTimelines.slice().map((val) => ({
                value: val,
                label: val,
              }))}
              onChange={(investmentTimeline) =>
                handleUpdateContact({
                  contact,
                  update: {
                    investmentPreferences: {
                      ...contact.investmentPreferences,
                      typicalInvestmentTimeline: investmentTimeline,
                    },
                  },
                })
              }
            />
          </ContactInputField>
          <InvestmentPreferences
            key={0}
            contact={contact}
            updateContact={(params) =>
              handleUpdateContact({
                contact: params.contact,
                update: params.update,
              })
            }
          />
        </ContentSection>
      )}
    </>
  )
}
