import { useCallback, useMemo } from "react"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import { isLoaded } from "common/utils/Loading"
import { CSVUploadTable } from "@components/inputs/csv/CSVUploadTable"
import {
  DealCRMIndividualContact,
  minimalFirmContactSchema,
  minimalIndividualContactSchema,
} from "common/model/DealCRM/DealCRMIndividualContact"
import { UnsafeRec } from "common/utils/RecordUtils"
import { mergeCSVSchemas } from "common/csv/CSVSchema"
import { getCRMContactTagName } from "common/model/DealCRM/DealCRMContact"
import { Validation } from "common/containers/Validation"
import { uniqueByRep } from "common/utils/data/Array/ArrayUtils"
import pluralize from "pluralize"
import {
  DealCRMFirmContact,
  viewDealCRMFirmIdFields,
} from "common/model/DealCRM/DealCRMFirmContact"
import { DealCRMNote, minimalContactNote } from "common/model/DealCRM/DealCRMNote"
import { useCRMContacts } from "src/pages/CRM/Providers/CRMContactsProvider"
import { commaSeparateWithAnd } from "common/utils/StringUtils"
import { Routes } from "src/Routes/Routes"
import { useNavigate } from "react-router-dom"
import { FirmNameCell } from "@components/inputs/csv/CellInputOverrides/FirmNameCell"
import { ContactEmailCell } from "@components/inputs/csv/CellInputOverrides/ContactEmailCell"
import { useCRMUploading } from "./useUploadFirmContacts"

export const AnotherContactCSVUploader = () => {
  const account = useCurrentAccount()
  const user = useLoggedInUser()
  const { findContactByTagName, findContactByEmail } = useCRMContacts()
  const { saveFirmContacts } = useCRMUploading()
  const navigate = useNavigate()

  const buttonSubtext = useCallback(
    (
      rows: (readonly [
        DealCRMFirmContact | null,
        DealCRMIndividualContact,
        Omit<DealCRMNote, "source"> | null
      ])[]
    ) => {
      if (rows.length === 0) return ""

      const uniqueFirms = uniqueByRep<DealCRMFirmContact | null, string | null>(
        (f) => f?.name ?? null
      )(
        rows.flatMap(([firm]) => {
          if (!firm) return []
          const existingFirm = findContactByTagName(getCRMContactTagName(firm))
          if (existingFirm) return []
          return [firm]
        })
      )
      const uniqueContacts = uniqueByRep<DealCRMIndividualContact, string>(
        (c) => `${c.firstName} ${c.lastName}`
      )(
        rows.flatMap(([, contact]) => {
          if (!contact) return []
          const existingContact = findContactByEmail(contact.email)
          if (existingContact) return []
          return [contact]
        })
      )
      const notes = rows.flatMap(([, , n]) => n ?? [])

      const uniqueData = [
        uniqueFirms.length > 0
          ? [`${uniqueFirms.length} ${pluralize("Firm", uniqueFirms.length)}`]
          : [],
        uniqueContacts.length > 0
          ? [`${uniqueContacts.length} ${pluralize("Contact", uniqueContacts.length)}`]
          : [],
        notes.length > 0 ? [`${notes.length} ${pluralize("Notes", uniqueContacts.length)}`] : [],
      ]

      return `Upload ${commaSeparateWithAnd(uniqueData.flat())}`
    },
    [findContactByEmail, findContactByTagName]
  )

  const submitContactsAndHoldings = useMemo(
    () =>
      async (
        rows: (readonly [
          DealCRMFirmContact | null,
          DealCRMIndividualContact,
          Omit<DealCRMNote, "source"> | null
        ])[]
      ) => {
        const uniqueFirms = uniqueByRep<DealCRMFirmContact | null, string | null>(
          (f) => f?.name ?? null
        )(rows.map(([firm]) => firm))
        await Promise.all(
          uniqueFirms.map((f) => {
            if (f === null) {
              return saveFirmContacts(
                null,
                rows.flatMap(([firm, contact, contactNote]) =>
                  firm ? [] : [{ contact, contactNote }]
                )
              )
            }
            return saveFirmContacts(
              f,
              rows.flatMap(([firm, contact, contactNote]) =>
                firm?.name === f.name
                  ? [
                      {
                        contact: { ...contact, firm: viewDealCRMFirmIdFields(f) },
                        contactNote,
                      },
                    ]
                  : []
              )
            )
          })
        )
        navigate(Routes.crm.allContacts)
      },
    [navigate, saveFirmContacts]
  )
  const firmSchema = useMemo(() => minimalFirmContactSchema(user.user), [user.user])
  const contactSchema = useMemo(() => minimalIndividualContactSchema(user.user), [user.user])
  const contactNote = useMemo(() => minimalContactNote(user.user), [user.user])
  const jointSchema = useMemo(
    () =>
      mergeCSVSchemas(
        mergeCSVSchemas(firmSchema, contactSchema, (l, r) => Validation.Ok([l, r] as const)),
        contactNote,
        (l, r) => Validation.Ok([...l, r] as const)
      ),
    [contactSchema, firmSchema, contactNote]
  )
  if (!isLoaded(account)) return null
  return (
    <CSVUploadTable
      schema={jointSchema}
      headers={UnsafeRec.keys(jointSchema.row)}
      initialContents={[]}
      submit={submitContactsAndHoldings}
      cellInputOverrides={{ firmName: FirmNameCell, email: ContactEmailCell }}
      buttonText={(rows) => `Upload ${rows.length} ${pluralize("Contacts", rows.length)}`}
      buttonSubtext={buttonSubtext}
      exampleFile={{
        description: "Upload your CSV with holdings and contacts. Below is the expected format:",
        filePath: "/assets/FirmContact2Example.csv",
      }}
    />
  )
}
