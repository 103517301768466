import { wrapIcon } from "./IconWrapper"

export const ContactInputCheckIcon = wrapIcon(
  <svg viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 11C3.2385 11 1 8.7615 1 6C1 3.2385 3.2385 1 6 1C8.7615 1 11 3.2385 11 6C11 8.7615 8.7615 11 6 11ZM5.14792 7.64642C5.34319 7.84169 5.6598 7.84168 5.85505 7.6464L8.68329 4.81776C8.87839 4.62264 8.87843 4.30632 8.68339 4.11114C8.48824 3.91585 8.17171 3.91579 7.97649 4.11101L5.85505 6.23245C5.65979 6.42771 5.34321 6.42771 5.14795 6.23245L4.4405 5.525C4.24527 5.32977 3.92873 5.32977 3.7335 5.525C3.53827 5.72023 3.53827 6.03677 3.7335 6.232L5.14792 7.64642Z"
      fill="currentColor"
    />
  </svg>
)
