/* eslint-disable react/no-unstable-nested-components */
import { Subtitle } from "@components/typography/Subtitle"
import { notification } from "@stories/components/Antd/notification"
import { AsyncDispatchButton } from "@stories/components/Button/AsyncDispatchButton"
import { Card } from "@stories/components/Card/Card"
import { Input } from "@stories/components/Inputs/Input/Input"
import Table from "@stories/components/Table/Table"
import Typography, { Color } from "@stories/components/Typography/Typography"
import { CheckCircleIcon } from "@stories/icons/CheckCircleIcon"
import { XIcon } from "@stories/icons/XIcon"
import { Row, createColumnHelper } from "@stories/components/Table"
import { DealDistribution } from "common/model/DealDistribution/DealDistribution"
import { DealDistributionParticipant } from "common/model/DealDistribution/DealDistributionParticipant"
import { Loading, defaultIfLoading, isLoading, matchLoading } from "common/utils/Loading"
import { emailPatternString } from "common/utils/StringUtils"
import { localeDateFormat } from "common/utils/dateUtils"
import { chunk } from "lodash"
import { useMemo, useState } from "react"
import { APIEndpoints, getAPIResponse } from "src/firebase/API"
import { useFirebaseReader } from "src/firebase/Context"
import { updateDealDistributionParticipant } from "src/firebase/dealDistributions"
import { DealDistributionParticipantStats } from "src/pages/DealDistributions/DealDistributionCreatorView/DealDistributionParticipantStats"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { handleConsoleError } from "src/utils/Tracking"

const ApproveUserButton = ({
  participant,
  dealDistribution,
}: {
  participant: DealDistributionParticipant
  dealDistribution: Loading<DealDistribution>
}) => {
  const db = useFirebaseReader()
  const handleInviteUser = (approvedDate: Date | null) =>
    updateDealDistributionParticipant({
      db,
      participant: { ...participant, status: { ...participant.status, approvedDate } },
    })

  return (
    <>
      {participant.status.approvedDate ? (
        <AsyncDispatchButton
          variant="hollow"
          size="xs"
          leftIcon={<CheckCircleIcon color={Color.Success} />}
          renderRawIcons
          label="Approved"
          onClick={() => handleInviteUser(null)}
          onSuccessProps={{ isDisabled: false }}
          isDisabled={matchLoading(
            dealDistribution,
            (d) => d.visibility === "closed" || d.visibility === "public",
            false,
            false
          )}
        />
      ) : (
        <AsyncDispatchButton
          variant="hollow"
          size="xs"
          label="Removed"
          leftIcon={<XIcon color={Color.Danger} />}
          renderRawIcons
          onClick={() => handleInviteUser(new Date())}
          onSuccessProps={{ isDisabled: false }}
          isDisabled={matchLoading(
            dealDistribution,
            (d) => d.visibility === "closed",
            false,
            false
          )}
        />
      )}
    </>
  )
}

const MultiRowActions = ({
  participants,
  dealDistribution,
}: {
  participants: DealDistributionParticipant[]
  dealDistribution: Loading<DealDistribution>
}) => {
  const db = useFirebaseReader()
  const handleInviteUsers = async (approvedDate: Date | null) => {
    for (const x of chunk(participants, 10)) {
      await Promise.all(
        x.map((participant) =>
          updateDealDistributionParticipant({
            db,
            participant: { ...participant, status: { ...participant.status, approvedDate } },
          })
        )
      )
    }
  }

  return (
    <div className="flex items-center gap-2 ">
      <AsyncDispatchButton
        variant="secondary"
        size="small"
        leftIcon={<XIcon color={Color.Danger} />}
        renderRawIcons
        label={`Remove All Visible (${participants.length})`}
        onClick={() => handleInviteUsers(null)}
        onSuccessProps={{ isDisabled: false }}
        isDisabled={matchLoading(
          dealDistribution,
          (d) => d.visibility === "closed" || d.visibility === "public",
          false,
          false
        )}
      />
      <AsyncDispatchButton
        variant="secondary"
        size="small"
        leftIcon={<CheckCircleIcon color={Color.Success} />}
        renderRawIcons
        label={`Approved All Visible (${participants.length})`}
        onClick={() => handleInviteUsers(new Date())}
        onSuccessProps={{ isDisabled: false }}
        isDisabled={matchLoading(
          dealDistribution,
          (d) => d.visibility === "closed" || d.visibility === "public",
          false,
          false
        )}
      />
    </div>
  )
}
const CopyDealLinkButton = ({ participant }: { participant: DealDistributionParticipant }) => {
  const user = useLoggedInUser()

  return (
    <>
      <AsyncDispatchButton
        onClick={() =>
          getAPIResponse<string>(
            APIEndpoints.getLinkForDealDistributionParticipant,
            {
              dealDistributionParticipantId: participant.id,
            },
            user
          )
            .then((response) => {
              response ? window.navigator.clipboard.writeText(response) : null
              notification.success({ message: `Link Copied to clipboard! \n ${response ?? ""}` })
            })
            .catch(handleConsoleError)
        }
        label="Copy Participant Magic Link"
        onClickedProps={{ isLoading: true }}
        size="xs"
      />
    </>
  )
}

export const AdminDealDistributionParticipantView = ({
  dealDistribution,
  participants,
}: {
  dealDistribution: DealDistribution
  participants: Loading<DealDistributionParticipant[]>
}) => {
  const user = useLoggedInUser()
  const columnHelper = createColumnHelper<DealDistributionParticipant>()
  const [participantEmail, setParticipantEmail] = useState<string>("")
  const [visibleRows, setVisibleRows] = useState<Row<DealDistributionParticipant>[]>([])

  const columns = useMemo(
    () => [
      columnHelper.accessor("status.approvedDate", {
        id: "approvedDate",
        header: "",
        cell: (context) => (
          <ApproveUserButton
            participant={context.row.original}
            dealDistribution={dealDistribution}
          />
        ),
        footer: (p) => p.column.id,
      }),
      columnHelper.accessor("participant.account.name", {
        id: "accountName",
        header: "Account Name",
        cell: (context) => context.getValue(),
        footer: (p) => p.column.id,
      }),
      columnHelper.accessor(
        (r) => `${r.participant.user.firstName} ${r.participant.user.lastName}`,
        {
          id: "userName",
          header: "User Name",
          cell: (context) => context.getValue(),
          footer: (p) => p.column.id,
        }
      ),
      columnHelper.accessor("participant.user.email", {
        id: "email",
        header: "Email",
        cell: (context) => context.getValue(),
        footer: (p) => p.column.id,
      }),
      columnHelper.accessor("status.sentDate", {
        id: "sentDate",
        header: "Sent Date",
        cell: (context) => (
          <div className="flex items-center gap-2">
            {context.row.original.status.sentDate
              ? localeDateFormat(context.row.original.status.sentDate)
              : "Not Sent"}
            <CopyDealLinkButton participant={context.row.original} />
          </div>
        ),
        footer: (p) => p.column.id,
      }),
    ],
    [columnHelper, dealDistribution]
  )

  return (
    <div className="flex flex-col gap-4">
      <DealDistributionParticipantStats
        dealDistributionParticipants={defaultIfLoading(participants, [])}
      />
      <Card>
        <Subtitle subtitle="Add Participants By Email" />
        <Typography text="Must be in airtable" />
        <Input
          placeholder="Add participant by email..."
          onChange={(e) => setParticipantEmail(e.target.value)}
          value={participantEmail}
        />
        <AsyncDispatchButton
          isDisabled={!emailPatternString.test(participantEmail)}
          onClick={() => {
            setParticipantEmail("")
            return getAPIResponse(
              APIEndpoints.addDealDistributionParticipant,
              {
                dealDistributionId: dealDistribution.id,
                participantEmail,
              },
              user
            ).catch(handleConsoleError)
          }}
          label="Add Participant"
          onSuccessProps={{ isDisabled: false }}
          onFailureProps={{ label: "Failed" }}
        />
      </Card>
      <MultiRowActions
        participants={visibleRows.map((r) => r.original)}
        dealDistribution={dealDistribution}
      />
      <Table
        columns={columns}
        data={defaultIfLoading(participants, [])}
        isLoading={isLoading(participants)}
        globalFilter={{ placeholder: "Search..." }}
        defaultSortBy="participant.account.name"
        defaultSortDirection="asc"
        isPaginationEnabled={false}
        onVisibleRowsChange={setVisibleRows}
      />
    </div>
  )
}
