import { createContext, ReactElement, useCallback, useContext, useEffect, useMemo } from "react"

type SaveMethod = "onSave" | "onBlur" | "onChange"

export interface ContactInputFieldProps {
  label: string
  value: string | number | boolean | null | undefined | string[]
  error: boolean
  check?: boolean
  variant?: "input" | "select"
  saveMethod?: SaveMethod | SaveMethod[]
  children: ReactElement
  tooltip?: string
  showLabel?: boolean
}

export const ContactInputContext = createContext<
  | (ContactInputFieldProps & {
      isEditing: boolean
      setIsEditing: (isEditing: boolean) => void
    })
  | null
>(null)

export const useContactInputField = () => {
  const context = useContext(ContactInputContext)
  if (!context) {
    throw new Error("useContactInputField must be wrapped in a <ContactInputField />")
  }

  const {
    label,
    value: propValue,
    error,
    check,
    variant = "input",
    saveMethod: saveMethodProp,
    tooltip,
    showLabel = true,
    children,
    isEditing,
    setIsEditing,
  } = context

  const placeholder = variant === "input" ? "Add" : "Select"

  const saveMethod = useMemo(() => {
    if (!saveMethodProp) return variant === "select" ? ["onChange"] : ["onBlur"]
    return Array.isArray(saveMethodProp) ? saveMethodProp : [saveMethodProp]
  }, [saveMethodProp, variant])

  const value = useMemo(() => {
    if (propValue === null || propValue === undefined) {
      return ""
    }

    if (propValue === true) {
      return "Yes"
    }

    if (propValue === false) {
      return "No"
    }

    if (Array.isArray(propValue)) {
      return propValue
    }
    return propValue.toString()
  }, [propValue])

  const handleClick = () => {
    setIsEditing(true)
  }

  const handleBlur = useCallback(() => {
    if (saveMethod.includes("onBlur") && !error) {
      setIsEditing(false)
    }
  }, [saveMethod, error, setIsEditing])

  useEffect(() => {
    if (saveMethod.includes("onChange") && !error) {
      setIsEditing(false)
    }
  }, [value, saveMethod, setIsEditing, error])

  return {
    label,
    value,
    error,
    check,
    placeholder,
    isEditing,
    setIsEditing,
    showLabel,
    handleClick,
    handleBlur,
    tooltip,
    children,
  }
}
