import { LabelValueList } from "@stories/components/LabelValueList/LabelValueList"
import Typography, { Color, Size } from "@stories/components/Typography/Typography"
import { DealCRMFirmContact } from "common/model/DealCRM/DealCRMFirmContact"
import { SelectContactButton } from "../../../Components/SelectContactButton"
import CRMContactCopyEmail from "../../ContactDisplay/ContactCopyEmail"
import { CRMContactName } from "../CRMContactName"
import { PrimaryContactButton } from "../PrimaryContactButton"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { sortByStringValue } from "common/utils/SortUtils"
import { useMemo } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import { UpdateContactProps, updateContact, updateIndividualContactFirm } from "src/firebase/crm"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { useCRMContacts } from "../../../Providers/CRMContactsProvider"

export const FirmAffiliatedBrokers = ({ contact }: { contact: DealCRMFirmContact }) => {
  const db = useFirebaseWriter()
  const user = useLoggedInUser()

  const handleUpdateContact = <C extends DealCRMContact>({
    contact: updatedContact,
    update,
  }: UpdateContactProps<C>) =>
    updateContact({ db, contact: updatedContact, update, user: user.user })

  const { contacts } = useCRMContacts()

  const firmIndividualContacts = useMemo(() => {
    const primaryContactId = contact.contacts.find((c) => c.isPrimaryContact)?.id
    // eslint-disable-next-line rulesdir/mutating-array-methods
    return contacts
      .flatMap((c) => (contact.contactIds.includes(c.id) && c.tag !== "firm" ? c : []))
      .slice()
      .sort(sortByStringValue("name"))
      .sort((a) => (a.id === primaryContactId ? -1 : 1)) // change to toSorted once polyfill situation is figured out
  }, [contact.contactIds, contact.contacts, contacts])

  const handleAddContactToFirm = async (c: DealCRMContact) => {
    if (c.tag === "individual" || c.tag === "broker") {
      await updateIndividualContactFirm({ db, contact: c, firm: contact })
    }
  }

  return (
    <LabelValueList
      title={contact.isBrokerage ? "Affiliated Brokers" : "Firm Contacts"}
      actions={
        <SelectContactButton
          sourceContactTag={contact.isBrokerage ? "brokerage" : "firm"}
          onContactCreated={async (_) => {}}
          onContactSelected={handleAddContactToFirm}
          sourceContact={contact}
          hideFirms
          contactFilter={(c) => (c.tag === "individual" || c.tag === "broker") && c.firm === null}
        />
      }
      items={
        firmIndividualContacts.length
          ? firmIndividualContacts.map((c) => ({
              label: (
                <div className="flex gap-2 items-center">
                  <CRMContactName contact={c} />
                  {c.tag === "individual" && c.title && (
                    <Typography size={Size.XSmall} color={Color.Subtitle} text={c.title} />
                  )}
                </div>
              ),
              value: <CRMContactCopyEmail textSize={Size.Small} email={c.email || ""} /> ?? "-",
              id: c.id,
              actionButton: (
                <PrimaryContactButton
                  contact={contact}
                  contactId={c.id}
                  updateContact={handleUpdateContact}
                />
              ),
            }))
          : [
              {
                id: "placeholder",
                label: "",
                value: (
                  <SelectContactButton
                    onContactCreated={async (_) => {}}
                    sourceContactTag={contact.isBrokerage ? "brokerage" : "firm"}
                    sourceContact={contact}
                    onContactSelected={handleAddContactToFirm}
                    hideFirms
                  />
                ),
              },
            ]
      }
    />
  )
}
