import { Button } from "@stories/components/Button/Button"
import { Logo } from "@stories/components/Logo/Logo"
import NavSection from "@stories/components/Nav/NavSection/NavSection"
import { NavigationProvider } from "@stories/components/Nav/Navigation/NavigationContext"
import { VerticalDotsIcon } from "@stories/icons/VerticalDotsIcon"
import { hasSomePlatformAccess } from "common/model/Auth/Permissions"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { navSections, useNavItemsToDisplay } from "src/Routes/NavItems"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { UserMenu } from "../UserMenu"
import NavbarCompanySearch from "./NavbarCompanySearch"
import { SearchIcon } from "@stories/icons/SearchIcon"
import { Routes } from "src/Routes/Routes"

export const MobileNavbar: React.FC<{ isMinimalNav: boolean }> = ({ isMinimalNav }) => {
  const currentUser = useLoggedInUser()
  const navigate = useNavigate()
  const [isExpanded, setIsExpanded] = useState(false)

  const navItems = useNavItemsToDisplay({ currentUser })

  const handleLogoClick = () => {
    if (hasSomePlatformAccess(currentUser.user.account)) {
      navigate(Routes.root)
    }
  }

  const closeMenu = () => {
    setIsExpanded(false)
  }

  return (
    <NavigationProvider mobileNav>
      <div className="relative">
        <div
          className={`fixed overflow-y-auto top-0 z-50 ${
            isExpanded ? "h-screen" : "h-16 justify-center"
          } flex flex-col p-4 w-full bg-neutral-1000`}
        >
          {!isMinimalNav ? (
            <div className="flex items-center justify-between h-8">
              <button
                type="button"
                onClick={() => handleLogoClick()}
                className="flex-shrink-0 flex items-center"
              >
                <Logo color="white" size="regular" />
              </button>
              <div className="flex gap-2 items-center">
                <Button
                  variant="hollow-dark"
                  leftIcon={<SearchIcon />}
                  onClick={() => setIsExpanded(!isExpanded)}
                />
                <Button
                  variant="hollow-dark"
                  leftIcon={<VerticalDotsIcon />}
                  onClick={() => setIsExpanded(!isExpanded)}
                />
              </div>
            </div>
          ) : (
            <Logo color="white" size="regular" />
          )}

          {isExpanded && !isMinimalNav && (
            <>
              <div className="my-8">
                <NavbarCompanySearch onSearch={closeMenu} />
              </div>
              <div className="flex flex-col gap-4">
                {navSections.map((section) => (
                  <div key={section}>
                    <NavSection
                      title={section}
                      items={navItems.filter((item) => item.section === section)}
                      iconsOnly={false}
                      onItemClick={closeMenu}
                    />
                  </div>
                ))}
                <UserMenu user={currentUser} />
              </div>
            </>
          )}
        </div>
      </div>
    </NavigationProvider>
  )
}
