import SkeletonLoader from "@components/icons/SkeletonLoader"
import { CurrentUser } from "@model/CurrentUser"
import { notification } from "@stories/components/Antd"
import { Button } from "@stories/components/Button/Button"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { CopyIcon } from "@stories/icons/CopyIcon"
import { cartaShareholderVerificationRoutes } from "common/model/carta/CartaShareholderVerificationRoute"
import { isLoaded } from "common/utils/Loading"
import { randomString } from "common/utils/RandomUtils"
import { SLACK_USERS } from "common/utils/SlackUtils"
import { useEffect, useState } from "react"
import { sendPlatformEventMessage } from "src/firebase/API"
import { useFirebaseWriter } from "src/firebase/Context"
import { PoweredByCarta } from "src/pages/Holdings/carta/PoweredByCarta"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { useFeatureFlag } from "src/providers/featureFlags/useFeatureFlags"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { handleConsoleError, trackEventInFirestoreAndHeap } from "src/utils/Tracking"

export const BrokerCartaLink = () => {
  const user = useLoggedInUser()
  const featureFlagEnabled = useFeatureFlag("carta_share_verification")

  if (!featureFlagEnabled) {
    return null
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row items-center gap-2">
        <Typography
          color={Color.Primary}
          weight={Weight.Semibold}
          size={Size.Medium}
          text="Share Verification"
        />
        <PoweredByCarta />
      </div>

      <Typography
        size={Size.Small}
        color={Color.Neutral1000}
        text="Share the private link below with your client to verify their holdings via Carta."
      />

      <BrokerLink currentUser={user} />
    </div>
  )
}

const BrokerLink = ({ currentUser }: { currentUser: CurrentUser }) => {
  const db = useFirebaseWriter()
  const { user } = currentUser
  const [token, setToken] = useState<string | null>(user.cartaShareholderToken ?? null)
  const firebase9 = useFirebase9()

  useEffect(() => {
    if (!isLoaded(user)) return
    if (user.cartaShareholderToken && user.cartaShareholderToken !== token) {
      setToken(user.cartaShareholderToken)
    } else if (!user.cartaShareholderToken) {
      const newToken = randomString()
      db.setCartaShareholderToken(user.id, newToken).catch(handleConsoleError)
    }
  }, [user, token, db])

  if (!isLoaded(user) || !user.cartaShareholderToken) {
    return <SkeletonLoader numRows={1} />
  }

  return (
    <CopyURL
      url={`${window.location.origin}${cartaShareholderVerificationRoutes.fullRoute(
        user.cartaShareholderToken
      )}`}
      afterCopy={() => {
        trackEventInFirestoreAndHeap(firebase9, user, "carta_share_verification_link_copied")
        sendPlatformEventMessage(
          `${user.firstName} ${user.lastName} copied the Carta share verification link. ${SLACK_USERS.Justin}`,
          currentUser
        ).catch(handleConsoleError)
      }}
    />
  )
}

const CopyURL = ({ url, afterCopy }: { url: string; afterCopy?: () => void }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(url).catch(handleConsoleError)
    notification.success({
      message: "Link Copied",
      placement: "top",
    })
    afterCopy?.()
  }

  return (
    <div className="flex flex-row items-center divide-x border rounded-md w-full lg:w-fit">
      <Button
        label="Copy"
        rightIcon={<CopyIcon />}
        variant="primary"
        size="medium"
        onClick={handleCopy}
      />
      <span className="px-2 pr-4 py-1 bg-neutral-200">
        <Typography size={Size.Small} color={Color.Neutral800} text={url} />
      </span>
    </div>
  )
}
