import InfoTooltip from "@components/InfoTooltip"
import { ContactInputCheckIcon } from "@stories/icons/ContactInputCheckIcon"
import { ContactInputUncheckIcon } from "@stories/icons/ContactInputUncheckIcon"
import React from "react"
import { Button } from "../Button/Button"
import Typography, { Color, Size, Weight } from "../Typography/Typography"
import {
  ContactInputContext,
  ContactInputFieldProps,
  useContactInputField,
} from "./ContactInputFieldContext"
import InputContent from "./InputContent"

const ContactInputFieldProvider: React.FC<ContactInputFieldProps> = (props) => {
  const [isEditing, setIsEditing] = React.useState(false)

  const memoizedProps = React.useMemo(
    () => ({ ...props, isEditing, setIsEditing }),
    [props, isEditing]
  )

  return (
    <ContactInputContext.Provider value={memoizedProps}>
      <ContactInputField />
    </ContactInputContext.Provider>
  )
}

const ContactInputField = () => {
  const { value, error, check, tooltip, label, isEditing, setIsEditing, showLabel } =
    useContactInputField()

  if (!showLabel) return <InputContent />

  return (
    <div>
      <div className="flex gap-1">
        <div className="w-4">
          {value && !error && check !== false ? (
            <ContactInputCheckIcon color={Color.Link} />
          ) : (
            <ContactInputUncheckIcon color={Color.Neutral400} />
          )}
        </div>
        <div className="flex flex-col">
          <div className="flex gap-2 ">
            <Typography
              weight={Weight.Semibold}
              color={error ? Color.Danger : Color.Neutral800}
              size={Size.XSmall}
              text={label}
            />

            {tooltip && <InfoTooltip className="w-44" text={tooltip} />}

            <div className="flex items-end" style={{ paddingBottom: 1 }}>
              {isEditing && (
                <Button
                  variant="hollow-link"
                  onClick={() => (!error ? setIsEditing(false) : null)}
                  label="Save"
                  size="xs"
                />
              )}
            </div>
          </div>

          <InputContent />
        </div>
      </div>
    </div>
  )
}

export default ContactInputFieldProvider
