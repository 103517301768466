import { Input } from "@stories/components/Inputs/Input/Input"
import { CSVUploaderCellRenderProps } from "../CSVUploadTable"
import { DangerIcon } from "@stories/icons/DangerIcon"
import { Color } from "@stories/components/Typography/Typography"

export const BaseCell = ({
  value,
  handleChange,
  icon,
}: CSVUploaderCellRenderProps & { icon?: React.ReactNode }) => (
  <div className=" min-w-36">
    <Input value={value} onChange={(e) => handleChange(e.target.value)} noBorder noBackground />
    {icon ? <div className="z-10 absolute right-2 top-2">{icon}</div> : null}
  </div>
)

export const StringCell = ({ value, handleChange, error }: CSVUploaderCellRenderProps) =>
  BaseCell({
    value,
    handleChange,
    icon: error ? <DangerIcon color={Color.Danger} tooltipTitle={error} /> : null,
  })
