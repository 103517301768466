import { LinkButton } from "@components/LinkButton"
import { UploadButton, UploadButtonProps } from "@components/upload/UploadButton"
import { RcFile } from "@stories/components/Antd/RcFile"
import { Upload } from "@stories/components/Antd/Upload/Upload"
import { Button } from "@stories/components/Button/Button"
import Typography, { Color } from "@stories/components/Typography/Typography"
import { collections } from "common/firestore/Collections"
import { AccountIdFields } from "common/model/Account"
import { constructDealCRMContactParser } from "common/model/DealCRM/DealCRMContact"
import {
  DealCRMFirmContactImportData,
  getKnownFirms,
} from "common/model/DealCRM/DealCRMFirmContact"
import { DealCRMImportJob, startCRMImportJob } from "common/model/DealCRM/DealCRMImportJob"
import {
  DealCRMIndividualContactImportData,
  getKnownIndividuals,
} from "common/model/DealCRM/DealCRMIndividualContact"
import { firestoreConverter } from "common/model/FirestoreConverter"
import { SubmittedDocument } from "common/model/files/DocumentSubmission"
import { printErrors } from "common/parser/ParseErrors"
import { Parser } from "common/parser/ParserClasses/Simple"
import { FirebaseCommon } from "common/firestore/Interface"
import { deprecatedIsLoaded, isLoading } from "common/utils/Loading"
import { UnsafeRec } from "common/utils/RecordUtils"
import { Either, Left } from "common/containers/Either"
import { useState } from "react"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { FileUtils } from "src/utils/FileUtils"
import { handleConsoleError } from "src/utils/Tracking"
import { useDbState } from "src/utils/useDb"
import { useDocumentSnapshot } from "src/utils/hooks/queries/useDocumentSnapshot"
import { v4 as uuidv4 } from "uuid"
import { ImportJobStatus } from "../ImportJobStatus"
import { ImportJobSummaryData } from "../ImportJobSummaryData"
import { ImportJobSummaryText } from "../ImportJobSummaryText"
import { ParsedCSVPreview } from "../ParsedCSVPreview"
import { Card } from "@stories/components/Card/Card"

type ContactCSVUploaderProps<T extends Record<keyof T, { toString: () => string }>> = {
  startImportJob: (
    data: SubmittedDocument,
    importCounts: ImportJobSummaryData
  ) => ReturnType<typeof startCRMImportJob>
  uploadButtonText: string
  fieldParsers: { [key in keyof T]: Parser<string, {}, T[key]> }
  headerNames: { [key in keyof T]: string }
  importJobSummary: (
    account: AccountIdFields,
    db: FirebaseCommon.LimitedDB,
    recs: T[]
  ) => Promise<ImportJobSummaryData>
  postParseValidation: (
    db: FirebaseCommon.LimitedDB,
    acc: AccountIdFields,
    t: T[]
  ) => Promise<Either<string[], T[]>>
  uploadJobId: string | undefined
  exampleLocation: string
}

export const ContactCSVUploader = <T extends Record<keyof T, { toString: () => string }>>(
  props: ContactCSVUploaderProps<T>
) => {
  const { user } = useLoggedInUser()
  const [fileList, setFileList] = useState<RcFile[]>([])

  const uploadEventFolder = uuidv4()

  const filePath = FileUtils.folders.documentUploads(user, uploadEventFolder)
  const [csvContents, setCSVContents] = useState<string>("")
  const contactCSVHeaders = UnsafeRec.keys(props.fieldParsers).map((k) => props.headerNames[k])
  const [uploadJobId, setUploadJobId] = useState<string | undefined>(props.uploadJobId)
  const uploadJob = useDocumentSnapshot(
    (db) =>
      uploadJobId
        ? db.db
            .collection(collections.dealCRM.importJobs)
            .withConverter<DealCRMImportJob>(firestoreConverter<DealCRMImportJob>())
            .doc(uploadJobId)
        : null,
    [uploadJobId]
  )
  const resetUploader = () => {
    setFileList([])
    setCSVContents("")
  }

  // the header parser here should really be a prop
  const parsed = constructDealCRMContactParser(props.fieldParsers).run(
    {},
    Array.from(csvContents)
  ).value // todo dedupe

  const uploadValidationResults = useDbState(
    (db) =>
      parsed.match(
        (err) => Promise.resolve(Left(err.messages)),
        (recs) => props.postParseValidation(db.db, user.account, recs)
      ),
    [csvContents]
  )
  const isInvalidUpload =
    uploadValidationResults === "loading" ||
    uploadValidationResults === null ||
    uploadValidationResults?.isLeft()

  const postValidationErrors = isLoading(uploadValidationResults)
    ? []
    : uploadValidationResults?.match(
        (errs) => errs,
        () => []
      )

  const importCounts = useDbState(
    (db) =>
      csvContents !== ""
        ? parsed.match(
            () => Promise.resolve(null),
            (recs) => props.importJobSummary(user.account, db.db, recs)
          )
        : Promise.resolve(null),
    [csvContents]
  )

  const handleUpload: UploadButtonProps["afterUpload"] = (submission) => {
    setFileList(() => [])
    return submission
      .match(
        (doc) => {
          const data = doc.data()
          if (data && deprecatedIsLoaded(importCounts)) {
            return parsed.match(
              (errs) => Promise.reject(new Error(printErrors(errs))),
              async (recs) =>
                props.startImportJob(data.document, importCounts).then((job) => {
                  setUploadJobId(job.id)
                  resetUploader()
                })
            )
          }
          return Promise.reject(new Error("Document submission reference found but had no data"))
        },
        () => Promise.reject(new Error("Document submission reference not found"))
      )
      .catch(handleConsoleError)
  }

  return (
    <>
      <div className="flex flex-col gap-4">
        {deprecatedIsLoaded(uploadJob) && uploadJob.status === "processing" ? null : (
          <Card>
            <div className="flex flex-col gap-4">
              <div>
                <Typography
                  color={Color.Primary}
                  text="Upload your CSV from your existing contact list. It will need to follow this structure to work with the importer:"
                />
              </div>
              <LinkButton href={props.exampleLocation} text="Download Example File" download />
            </div>
          </Card>
        )}
        <ImportJobStatus
          onResetUpload={csvContents ? resetUploader : undefined}
          uploadJob={uploadJob}
        />
        {deprecatedIsLoaded(uploadJob) && uploadJob.status === "processing" ? null : (
          <div className="flex flex-col gap-4">
            {fileList.length >= 1 ? (
              <Button onClick={resetUploader} variant="primary" size="large" label="Remove File" />
            ) : (
              <Upload.Dragger
                showUploadList={false}
                fileList={fileList}
                beforeUpload={async (f) => {
                  await f.text().then((t) => {
                    setCSVContents(t)
                  })
                  setFileList((prevFileList) => [...prevFileList, f])
                  return false
                }}
                onRemove={(f) => {
                  setFileList((prevFileList) => prevFileList.filter((file) => file !== f))
                }}
                maxCount={1}
              >
                <div className="p-4">
                  <Button
                    isFullWidth
                    variant="primary"
                    size="large"
                    label="Drop or Select File(s)"
                  />
                </div>
              </Upload.Dragger>
            )}

            <ParsedCSVPreview
              fieldParsers={props.fieldParsers}
              csvContents={csvContents}
              headers={contactCSVHeaders}
              renderParsed={(r) => UnsafeRec.values(r).map((x) => x.toString())}
              postValidationErrors={postValidationErrors}
            />
            <div className="grid grid-cols-2 gap-4">
              <ImportJobSummaryText summary={importCounts} />
              <UploadButton
                text={props.uploadButtonText}
                files={fileList}
                filePath={filePath}
                user={user}
                disabled={isInvalidUpload || !deprecatedIsLoaded(importCounts)}
                afterUpload={handleUpload}
                documentDate={new Date()}
                documentType="crm-contact-upload"
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export const firmImportJobSummary = async (
  a: AccountIdFields,
  db: FirebaseCommon.LimitedDB,
  recs: DealCRMFirmContactImportData[]
): Promise<ImportJobSummaryData> => {
  const knownFirms = await getKnownFirms(db, a.id)
  const importedSourceIds = recs.map((r) => r.sourceId)
  const existingFirms = knownFirms.flatMap((f) => (importedSourceIds.includes(f.sourceId) ? f : []))
  return {
    firmsCount: recs.length,
    newFirmsImportedCount: recs.length - existingFirms.length,
    individualsCount: 0,
    newIndividualsImportedCount: 0,
  }
}

export const individualImportJobSummary = async (
  a: AccountIdFields,
  db: FirebaseCommon.LimitedDB,
  recs: DealCRMIndividualContactImportData[]
): Promise<ImportJobSummaryData> => {
  const knownIndividuals = await getKnownIndividuals(db, a.id)
  return {
    individualsCount: recs.length,
    newIndividualsImportedCount: recs.filter(
      (r) => !knownIndividuals.some((f) => f.sourceId === r.sourceId)
    ).length,
    firmsCount: 0,
    newFirmsImportedCount: 0,
  }
}
