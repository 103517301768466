import { collections } from "common/firestore/Collections"
import { useNavigate } from "react-router-dom"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"

import Alert from "@stories/components/Alert/Alert"
import { InfoIcon } from "@stories/icons/InfoIcon"
import { Routes } from "../Routes/Routes"
import { isGhosting } from "../Routes/auth/UserAuthContext"
import { useFirebaseReader } from "../firebase/Context"
import { Color } from "@stories/components/Typography/Typography"

const GhostingStatusBar = () => {
  const user = useLoggedInUser()
  const firebase = useFirebaseReader()
  const navigate = useNavigate()

  const stopGhosting = () => {
    const realUserAccount = user.user.ghostingUserAccount || user.user.account
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    firebase.db
      .collection(collections.users)
      .doc(user.user.id)
      .update({
        account: realUserAccount,
        ghostingUserAccount: null,
        ghostSessionStartedAt: null,
      })
      .then(() => {
        navigate(Routes.admin.accounts)
      })
      .catch()
  }

  if (!isGhosting(user)) return null

  return (
    <div className="flex flex-col z-30">
      <Alert
        headline="Ghosting Account"
        variant="warning"
        icon={<InfoIcon color={Color.Warning} />}
        isFullWidthBanner
        onCloseButtonClick={stopGhosting}
        message={
          <p className="text-neutral-1000 text-sm lg:whitespace-nowrap">
            You are ghosting account {user.user.account.name}
          </p>
        }
      />
    </div>
  )
}

export default GhostingStatusBar
