import { ContactDetailsDrawerWrapper } from "./Contacts/ContactDetailsDrawer/ContactDetailsDrawer"
import { BuySellInterestProvider } from "./Providers/BuySellInterestProvider"
import { CRMContactsProvider } from "./Providers/CRMContactsProvider"
import { CRMDealsProvider } from "./Providers/CRMDealsProvider"
import { CRMFundsProvider } from "./Providers/CRMFundsProvider"
import { ContactHoldingsProvider } from "./Providers/ContactHoldingsProvider"

export const ContactDetailsWrapper: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <ContactDetailsDrawerWrapper>
    <CRMFundsProvider>{children}</CRMFundsProvider>
  </ContactDetailsDrawerWrapper>
)

export const CRMProviders: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <CRMContactsProvider>
    <BuySellInterestProvider>
      <ContactHoldingsProvider>
        <CRMDealsProvider>{children}</CRMDealsProvider>
      </ContactHoldingsProvider>
    </BuySellInterestProvider>
  </CRMContactsProvider>
)
