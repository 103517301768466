import Divider from "@stories/components/Divider/Divider"
import { Color, Size, Typography, Weight } from "@stories/components/Typography/Typography"
import React from "react"

export const ContentSection = ({
  title,
  children,
  divider,
}: {
  title: string
  children?: (JSX.Element | undefined | null)[]
  divider?: boolean
}) => {
  if (!children || children.every((child) => !child)) return null

  return (
    <>
      {divider && <Divider />}
      <div className="flex-col p-2">
        <div className="pb-2">
          <Typography
            text={title}
            size={Size.Small}
            weight={Weight.Semibold}
            color={Color.Neutral800}
          />
        </div>
        <div>{children}</div>
      </div>
    </>
  )
}
