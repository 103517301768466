import React from "react"
import { useContactInputField } from "./ContactInputFieldContext"
import Typography, { Color, Size } from "../Typography/Typography"

const InputText = ({ text }: { text: string }) => (
  <Typography color={Color.Neutral800} size={Size.XSmall} text={text} />
)

const PlaceholderText = ({ text }: { text: string }) => (
  <Typography color={Color.Subtitle} size={Size.XSmall} text={text} />
)

export default function InputValue() {
  const { placeholder, value } = useContactInputField()
  if (!value) return <PlaceholderText text={placeholder} />

  if (typeof value === "string") return <InputText text={value} />

  if (!value.length) return <PlaceholderText text={placeholder} />

  return (
    <div className="flex flex-col gap-1 py-1">
      {value.map((v) => (
        <InputText key={v} text={v} />
      ))}
    </div>
  )
}
