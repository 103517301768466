import { PageTitle } from "@components/typography/PageTitle"
import { useCRMContacts } from "../Providers/CRMContactsProvider"
import { Button } from "@stories/components/Button/Button"
import { ChevronLeftIcon } from "@stories/icons/ChevronLeftIcon"
import { Routes } from "src/Routes/Routes"
import { useNavigate } from "react-router-dom"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import { isLoaded } from "common/utils/Loading"
import { FirmContactCSVUploader } from "../Components/Uploader/Uploaders/FirmContactCSVUploader"
import { useState } from "react"
import { CreateContactTagCard } from "../Contacts/CreateContactButton/CreateContactTagCard"
import { shortDateFormat } from "common/utils/dateUtils"
import { AnotherContactCSVUploader } from "../Components/Uploader/Uploaders/NewFirmContactCSVUploader"
import { buildAccountCRMSettings } from "common/model/AccountCRMSettings"
import { FirmContactHoldingsUploader } from "../Components/Uploader/Uploaders/FirmContactHoldingsUploader"
import { useFeatureFlag } from "src/providers/featureFlags/useFeatureFlags"
import { FirmContactInterestUploader } from "../Components/Uploader/Uploaders/FirmContactInterestUploader"

export const ImportContactsPage = () => {
  const account = useCurrentAccount()
  const navigate = useNavigate()
  const [uploadType, setUploadType] = useState<
    "firm" | "individual" | "joint" | "contactsWithHoldings" | "contactsWithBuySellInterest"
  >()
  const { latestFirmImport, latestIndividualImport } = useCRMContacts()
  const isNewCRMUploaderEnabled = useFeatureFlag("crm_new_uploader")

  if (!isLoaded(account)) return null
  return (
    <div className="px-4 pt-20 md:pt-4 pb-4 m-auto animate-fade">
      <div className="flex flex-col gap-4 items-start">
        <Button
          leftIcon={<ChevronLeftIcon />}
          label="Back to CRM Contacts"
          variant="hollow"
          onClick={() => navigate(Routes.crm.allContacts)}
        />
        <PageTitle title="Import Data" />
      </div>
      <div className="mt-4 flex flex-col gap-4  ">
        {!uploadType ? (
          <div className="flex flex-col gap-4 w-full max-w-4xl m-auto">
            {buildAccountCRMSettings(account)?.thirdPartyCRM ? (
              <>
                <CreateContactTagCard
                  label="Upload Fund/Org Contacts"
                  subLabel={
                    latestFirmImport
                      ? `Latest import at ${shortDateFormat(latestFirmImport.createdAt)}${
                          latestFirmImport.status === "processing"
                            ? " : Running"
                            : latestFirmImport.status === "failed"
                            ? " : Failed"
                            : ""
                        }`
                      : undefined
                  }
                  onClick={() => setUploadType("firm")}
                />
              </>
            ) : null}
            {!buildAccountCRMSettings(account)?.thirdPartyCRM || isNewCRMUploaderEnabled ? (
              <CreateContactTagCard
                label="Upload Contacts"
                onClick={() => setUploadType("joint")}
              />
            ) : null}
            {isNewCRMUploaderEnabled ? (
              <>
                <CreateContactTagCard
                  label="Upload Contacts With Holdings"
                  onClick={() => setUploadType("contactsWithHoldings")}
                />
                <CreateContactTagCard
                  label="Upload Contacts With Buy Sell Interest"
                  onClick={() => setUploadType("contactsWithBuySellInterest")}
                />
              </>
            ) : null}
          </div>
        ) : null}
        {uploadType === "firm" ? <FirmContactCSVUploader /> : null}
        {/* {uploadType === "individual" ? <IndividualContactCSVUploader /> : null} */}
        {uploadType === "joint" ? <AnotherContactCSVUploader /> : null}
        {uploadType === "contactsWithHoldings" ? <FirmContactHoldingsUploader /> : null}
        {uploadType === "contactsWithBuySellInterest" ? <FirmContactInterestUploader /> : null}
      </div>
    </div>
  )
}
