import { TextInput } from "@components/inputs/TextInputField"
import { Select } from "@stories/components/Antd"
import {
  NumberField,
  sizeScaledInputWarning,
} from "@stories/components/Inputs/NumberInput/NumberField"
import { LabelValueList } from "@stories/components/LabelValueList/LabelValueList"
import Typography, { Color, Size, Weight } from "@stories/components/Typography/Typography"
import { firmTypeOptions } from "common/model/Account"
import { InvestmentTimeline, InvestmentTimelines } from "common/model/DealCRM/ContactInvestmentPreferences"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { DealCRMFirmContact } from "common/model/DealCRM/DealCRMFirmContact"
import { sortByStringValue } from "common/utils/SortUtils"
import { formatAbbreviatedCurrency } from "common/utils/math/format"
import { isNil } from "lodash"
import { useMemo } from "react"
import { useFirebaseWriter } from "src/firebase/Context"
import { UpdateContactProps, updateContact, updateIndividualContactFirm } from "src/firebase/crm"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { SelectContactButton } from "../../Components/SelectContactButton"
import { useCRMContacts } from "../../Providers/CRMContactsProvider"
import CRMContactCopyEmail from "../ContactDisplay/ContactCopyEmail"
import { CRMContactName } from "./CRMContactName"
import FirmEmployeesTable from "./FirmEmployeesTable"
import { InvestmentPreferences } from "./InvestmentPreferences"
import { PrimaryContactButton } from "./PrimaryContactButton"

export const FirmContactDetails = ({ contact }: { contact: DealCRMFirmContact }) => {
  const db = useFirebaseWriter()
  const user = useLoggedInUser()

  const handleUpdateContact = <C extends DealCRMContact>({
    contact: updatedContact,
    update,
  }: UpdateContactProps<C>) =>
    updateContact({ db, contact: updatedContact, update, user: user.user })

  const { contacts } = useCRMContacts()

  const firmIndividualContacts = useMemo(() => {
    const primaryContactId = contact.contacts.find((c) => c.isPrimaryContact)?.id
    // eslint-disable-next-line rulesdir/mutating-array-methods
    return contacts
      .flatMap((c) => (contact.contactIds.includes(c.id) && c.tag !== "firm" ? c : []))
      .slice()
      .sort(sortByStringValue("name"))
      .sort((a) => (a.id === primaryContactId ? -1 : 1)) // change to toSorted once polyfill situation is figured out
  }, [contact.contactIds, contact.contacts, contacts])

  const handleAddContactToFirm = async (c: DealCRMContact) => {
    if (c.tag === "individual" || c.tag === "broker") {
      await updateIndividualContactFirm({ db, contact: c, firm: contact })
    }
  }

  return (
    <div className="flex flex-col gap-3">
      <LabelValueList
        title={contact.isBrokerage ? "Affiliated Brokers" : "Contacts"}
        actions={
          <SelectContactButton
            sourceContactTag={contact.isBrokerage ? "brokerage" : "firm"}
            onContactCreated={async (_) => {}}
            onContactSelected={handleAddContactToFirm}
            sourceContact={contact}
            hideFirms
            contactFilter={(c) => (c.tag === "individual" || c.tag === "broker") && c.firm === null}
          />
        }
        items={
          firmIndividualContacts.length
            ? firmIndividualContacts.map((c) => ({
                label: (
                  <div className="flex gap-2 items-center">
                    <CRMContactName contact={c} />
                    {c.tag === "individual" && c.title && (
                      <Typography size={Size.XSmall} color={Color.Subtitle} text={c.title} />
                    )}
                  </div>
                ),
                value: <CRMContactCopyEmail textSize={Size.Small} email={c.email || ""} /> ?? "-",
                id: c.id,
                actionButton: (
                  <PrimaryContactButton
                    contact={contact}
                    contactId={c.id}
                    updateContact={handleUpdateContact}
                  />
                ),
              }))
            : [
                {
                  id: "placeholder",
                  label: "",
                  value: (
                    <SelectContactButton
                      onContactCreated={async (_) => {}}
                      sourceContactTag={contact.isBrokerage ? "brokerage" : "firm"}
                      sourceContact={contact}
                      onContactSelected={handleAddContactToFirm}
                      hideFirms
                    />
                  ),
                },
              ]
        }
      />
      <div className="flex flex-wrap gap-3 gap-x-4">
        <div className="flex-1 flex flex-col gap-2">
          <Typography
            size={Size.Medium}
            weight={Weight.Semibold}
            text={contact.isBrokerage ? "Broker Details" : "Fund Details"}
          />
          <LabelValueList
            title=""
            items={(() => {
              const commonItems = [
                {
                  id: "location",
                  label: "Location",
                  value: (
                    <TextInput
                      name="Location"
                      value={contact.location?.city ?? ""}
                      onChange={(e) =>
                        handleUpdateContact({
                          contact,
                          update: {
                            location: isNil(e.target.value)
                              ? {
                                  city: "",
                                  state: "",
                                  country: "",
                                }
                              : {
                                  city: e.target.value,
                                  state: contact.location?.state ?? "",
                                  country: contact.location?.country ?? "",
                                },
                          },
                        })
                      }
                    />
                  ),
                },
              ]
              const notBrokerageItems = [
                {
                  id: "type",
                  label: "Type",
                  value: (
                    <Select<string>
                      style={{ width: "200px" }}
                      value={contact.classification}
                      options={firmTypeOptions
                        .slice()
                        .sort() // change to toSorted once polyfill situation is figured out
                        .map((val) => ({
                          value: val,
                          label: val,
                        }))}
                      onChange={(classification) =>
                        handleUpdateContact({
                          contact,
                          update: {
                            classification,
                          },
                        })
                      }
                    />
                  ),
                },
                {
                  id: "aum",
                  label: "AUM",
                  value: (
                    <NumberField
                      placeholder="AUM ($)"
                      value={contact.aum}
                      onChange={(newAUM) =>
                        handleUpdateContact({
                          contact,
                          update: {
                            aum: isNil(newAUM) ? null : newAUM,
                          },
                        })
                      }
                      formatter={formatAbbreviatedCurrency}
                      inputWarningFunction={sizeScaledInputWarning}
                    />
                  ),
                },
                {
                  id: "targetCheckSize",
                  label: "Target Check Size",
                  value: (
                    <div className="flex items-center gap-4">
                      <NumberField
                        placeholder="Min"
                        value={contact.targetCheckSize?.lowerBound}
                        onChange={(lowerBound) =>
                          handleUpdateContact({
                            contact,
                            update: {
                              targetCheckSize: {
                                lowerBound: isNil(lowerBound) ? null : lowerBound,
                                upperBound: contact.targetCheckSize?.upperBound ?? null,
                              },
                            },
                          })
                        }
                        formatter={formatAbbreviatedCurrency}
                        inputWarningFunction={sizeScaledInputWarning}
                      />
                      <span>-</span>
                      <NumberField
                        placeholder="Max"
                        value={contact.targetCheckSize?.upperBound}
                        onChange={(upperBound) =>
                          handleUpdateContact({
                            contact,
                            update: {
                              targetCheckSize: {
                                lowerBound: contact.targetCheckSize?.lowerBound ?? null,
                                upperBound: isNil(upperBound) ? null : upperBound,
                              },
                            },
                          })
                        }
                        formatter={formatAbbreviatedCurrency}
                        inputWarningFunction={sizeScaledInputWarning}
                      />
                    </div>
                  ),
                },
                {
                  id: "typicalInvestmentTimeline",
                  label: "Typical Investment Timeline",
                  value: (
                    <Select<InvestmentTimeline>
                      style={{ width: "200px" }}
                      value={contact.investmentPreferences.typicalInvestmentTimeline}
                      options={InvestmentTimelines.slice().map((val) => ({
                        value: val,
                        label: val,
                      }))}
                      onChange={(investmentTimeline) =>
                        handleUpdateContact({
                          contact,
                          update: {
                            investmentPreferences: {
                              ...contact.investmentPreferences,
                              typicalInvestmentTimeline: investmentTimeline,
                            },
                          },
                        })
                      }
                    />
                  ),
                },
              ]

              // TODO: Implement Company Focus
              // const brokerageItems = [
              //   {
              //     id: "companyFocus",
              //     label: "Company Focus",
              //     value: (
              //       <ContactCompanyFocusList
              //         contact={contact}
              //         updateContact={(params) =>
              //           handleUpdateContact({
              //             contact: params.contact,
              //             update: params.update,
              //           })
              //         }
              //       />
              //     ),
              //   },
              // ]
              // return contact.isBrokerage
              //   ? [...commonItems, ...brokerageItems]
              //   : [...commonItems, ...notBrokerageItems]

              return contact.isBrokerage ? [...commonItems] : [...commonItems, ...notBrokerageItems]
            })()}
          />
        </div>
        {!contact.isBrokerage && (
          <InvestmentPreferences
            contact={contact}
            updateContact={(params) =>
              handleUpdateContact({
                contact: params.contact,
                update: params.update,
              })
            }
          />
        )}
      </div>
      {!contact.isBrokerage && contact.postgresWebsiteDomain ? (
        <FirmEmployeesTable
          name={contact.name}
          postgresWebsiteDomain={contact.postgresWebsiteDomain}
        />
      ) : null}
    </div>
  )
}
