import ContactInputField from "@stories/components/ContactInputField/ContactInputField"
import { Input } from "@stories/components/Inputs/Input/Input"
import PhoneInput from "@stories/components/Inputs/PhoneInput/PhoneInput"
import { Size } from "@stories/components/Typography/Typography"
import { DealCRMBrokerContact } from "common/model/DealCRM/DealCRMBrokerContact"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { isNil } from "lodash"
import { useFirebaseWriter } from "src/firebase/Context"
import { UpdateContactProps, updateContact } from "src/firebase/crm"
import { StringField } from "src/pages/CRM/Components/InputFields/StringField"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import CRMContactCopyEmail from "../../ContactDisplay/ContactCopyEmail"
import { ContactFirmSelector } from "../ContactFirmSelector"
import { ContentSection } from "./ContentSection"

export const BrokerContactDetails = ({ contact }: { contact: DealCRMBrokerContact }) => {
  const db = useFirebaseWriter()
  const user = useLoggedInUser()

  const handleUpdateContact = <C extends DealCRMContact>({
    contact: updatedContact,
    update,
  }: UpdateContactProps<C>) =>
    updateContact({ db, contact: updatedContact, update, user: user.user })

  return (
    <>
      <ContentSection title="Contact Details">
        <ContactInputField
          label="Brokerage"
          value={contact.firm?.name}
          error={false}
          variant="select"
        >
          <ContactFirmSelector<DealCRMBrokerContact>
            contact={contact}
            onFirmSelected={(firm) =>
              handleUpdateContact({
                contact,
                update: {
                  firm,
                },
              })
            }
            renderButton={(onClick) => (
              <Input
                onFocus={onClick}
                autoFocus
                placeholder="Brokerage"
                value={contact.firm?.name ?? ""}
                size="xsmall"
                readOnly
              />
            )}
          />
        </ContactInputField>
        <ContactInputField label="Email" value={contact.email} error={false} variant="input">
          <div className="flex gap-2 items-center">
            <StringField
              autoFocus
              placeholder="email@company.com"
              value={contact.email}
              onChange={(email) =>
                handleUpdateContact({
                  contact,
                  update: {
                    email: email ?? "",
                  },
                })
              }
            />
            <CRMContactCopyEmail textSize={Size.Small} email={contact.email || ""} iconOnly />
          </div>
        </ContactInputField>
        <ContactInputField label="Phone" value={contact.phone} error={false} variant="input">
          <PhoneInput
            autoFocus
            value={contact.phone ?? undefined}
            onChange={(phoneNumber) =>
              handleUpdateContact({
                contact,
                update: {
                  phone: phoneNumber ?? "",
                },
              })
            }
            heapName="crm-contact-drawer-phone-input"
          />
        </ContactInputField>
        <ContactInputField
          label="Location"
          value={contact.location?.city}
          error={false}
          variant="input"
        >
          <StringField
            autoFocus
            placeholder="Location"
            value={contact.location?.city}
            onChange={(city) =>
              handleUpdateContact({
                contact,
                update: {
                  location: isNil(city)
                    ? {
                        city: "",
                        state: "",
                        country: "",
                      }
                    : {
                        city,
                        state: contact.location?.state ?? "",
                        country: contact.location?.country ?? "",
                      },
                },
              })
            }
          />
        </ContactInputField>
      </ContentSection>
    </>
  )
}
