import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { isLoaded } from "common/utils/Loading"
import Spinner from "../../../../components/icons/Spinner"
import { ContactNotes } from "./ContactNotes/ContactNotes"
import { useContactBuySellInterest } from "./helpers"

const ContactDetailsDrawerNotes = ({ contact }: { contact: DealCRMContact }) => {
  const contactBuySellInterest = useContactBuySellInterest({
    contact,
  })

  if (!isLoaded(contactBuySellInterest)) return <Spinner size="sm" />

  if (!contact)
    return (
      <div>
        Could not find contact details. This is likely an issue, please contact us at
        contact@caplight.com to let us know about the issue
      </div>
    )

  return (
    <div className="flex flex-col gap-6 p-6 bg-neutral-200 min-h-full">
      <div className="p-3 bg-white rounded border">
        <ContactNotes contact={contact} contactBuySellInterest={contactBuySellInterest} />
      </div>
    </div>
  )
}

export default ContactDetailsDrawerNotes
