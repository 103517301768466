import { useCallback, useMemo } from "react"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import { isLoaded } from "common/utils/Loading"
import { CSVUploadTable } from "@components/inputs/csv/CSVUploadTable"
import {
  DealCRMIndividualContact,
  minimalFirmContactSchema,
  minimalIndividualContactSchema,
} from "common/model/DealCRM/DealCRMIndividualContact"
import { UnsafeRec } from "common/utils/RecordUtils"
import { mergeCSVSchemas } from "common/csv/CSVSchema"
import { useLazyStatefulCompanyLookupTable } from "src/queries/Company/useLazyCompanyLookupTable"
import {
  MinimalHolding,
  minimalHoldingSchema,
} from "common/model/holdings/Holding"
import { Validation } from "common/containers/Validation"
import { uniqueByRep } from "common/utils/data/Array/ArrayUtils"
import pluralize from "pluralize"
import {
  DealCRMFirmContact,
  viewDealCRMFirmIdFields,
} from "common/model/DealCRM/DealCRMFirmContact"
import {
  DealCRMNote,
  minimalContactNote,
  minimalHoldingNote,
} from "common/model/DealCRM/DealCRMNote"
import { commaSeparateWithAnd } from "common/utils/StringUtils"
import { Routes } from "src/Routes/Routes"
import { useNavigate } from "react-router-dom"
import { CompanyNameCell } from "@components/inputs/csv/CellInputOverrides/CompanyNameCell"
import { FirmNameCell } from "@components/inputs/csv/CellInputOverrides/FirmNameCell"
import { ContactEmailCell } from "@components/inputs/csv/CellInputOverrides/ContactEmailCell"
import { useCRMUploading } from "./useUploadFirmContacts"


export const FirmContactHoldingsUploader = () => {
  const account = useCurrentAccount()
  const user = useLoggedInUser()
  const { saveFirmContacts } = useCRMUploading()
  const lookup = useLazyStatefulCompanyLookupTable("name")
  const navigate = useNavigate()

  const buttonSubtext = useCallback(
    (
      rows: (readonly [
        DealCRMFirmContact | null,
        DealCRMIndividualContact,
        Omit<DealCRMNote, "source"> | null,
        MinimalHolding,
        Omit<DealCRMNote, "source"> | null
      ])[]
    ) => {
      if (rows.length === 0) return ""

      const uniqueFirms = uniqueByRep<DealCRMFirmContact | null, string | null>(
        (f) => f?.name ?? null
      )(rows.map(([firm]) => firm))
      const uniqueContacts = uniqueByRep<DealCRMIndividualContact, string>(
        (c) => `${c.firstName} ${c.lastName}`
      )(rows.map(([, contact]) => contact))
      const uniqueHoldings = uniqueByRep<MinimalHolding, string>((h) => `${h?.id ?? "none"}`)(
        rows.map(([, , , holding]) => holding)
      ).flatMap((h) => h ?? [])

      const uniqueData = [
        uniqueFirms.length > 0
          ? [`${uniqueFirms.length} ${pluralize("Firm", uniqueFirms.length)}`]
          : [],
        uniqueContacts.length > 0
          ? [`${uniqueContacts.length} ${pluralize("Contact", uniqueContacts.length)}`]
          : [],
        uniqueHoldings.length > 0
          ? [`${uniqueHoldings.length} ${pluralize("Holding", uniqueHoldings.length)}`]
          : [],
      ]

      return `Upload ${commaSeparateWithAnd(uniqueData.flat())}`
    },
    []
  )

  const submitContactsAndHoldings = useMemo(
    () =>
      async (
        rows: (readonly [
          DealCRMFirmContact | null,
          DealCRMIndividualContact,
          Omit<DealCRMNote, "source"> | null,
          MinimalHolding,
          Omit<DealCRMNote, "source"> | null
        ])[]
      ) => {
        const uniqueFirms = uniqueByRep<DealCRMFirmContact | null, string | null>(
          (f) => f?.name ?? null
        )(rows.map(([firm]) => firm))
        await Promise.all(
          uniqueFirms.map((f) => {
            if (f === null) {
              return saveFirmContacts(
                null,
                rows.flatMap(([firm, contact, contactNote, holding, holdingNote]) =>
                  firm ? [] : [{ contact, contactNote, holding, holdingNote }]
                )
              )
            }
            return saveFirmContacts(
              f,
              rows.flatMap(([firm, contact, contactNote, holding, holdingNote]) =>
                firm?.name === f.name
                  ? [
                      {
                        contact: { ...contact, firm: viewDealCRMFirmIdFields(f) },
                        holding,
                        contactNote,
                        holdingNote,
                      },
                    ]
                  : []
              )
            )
          })
        )
        navigate(Routes.crm.allContacts)
      },
    [navigate, saveFirmContacts]
  )
  const firmSchema = useMemo(() => minimalFirmContactSchema(user.user), [user.user])
  const holdingSchema = useMemo(() => minimalHoldingSchema(user.user, lookup), [lookup, user.user])
  const contactSchema = useMemo(() => minimalIndividualContactSchema(user.user), [user.user])
  const holdingNote = useMemo(() => minimalHoldingNote(user.user), [user.user])
  const contactNote = useMemo(() => minimalContactNote(user.user), [user.user])
  const jointSchema = useMemo(
    () =>
      mergeCSVSchemas(
        mergeCSVSchemas(
          mergeCSVSchemas(firmSchema, contactSchema, (l, r) => Validation.Ok([l, r] as const)),
          mergeCSVSchemas(contactNote, holdingSchema, (l, r) => Validation.Ok([l, r] as const)),
          (l, r) => Validation.Ok([...l, ...r] as const)
        ),
        holdingNote,
        (l, r) => Validation.Ok([...l, r] as const)
      ),
    [contactSchema, firmSchema, holdingSchema, holdingNote, contactNote]
  )
  if (!isLoaded(account)) return null
  return (
    <CSVUploadTable
      schema={jointSchema}
      headers={UnsafeRec.keys(jointSchema.row)}
      cellInputOverrides={{
        company: CompanyNameCell,
        firmName: FirmNameCell,
        email: ContactEmailCell,
      }}
      initialContents={[]}
      submit={submitContactsAndHoldings}
      buttonText={(rows) => `Upload ${rows.length} ${pluralize("Holding", rows.length)}`}
      buttonSubtext={buttonSubtext}
      exampleFile={{
        description: "Upload your CSV with holdings and contacts. Below is the expected format:",
        filePath: "/assets/FirmContactHoldingsExample.csv",
      }}
    />
  )
}
