import TradingRegistrationStatusBar from "@components/TradingRegistration/TradingRegistrationStatusBar"
import Navigation from "@stories/components/Nav/Navigation/Navigation"
import { ReactNode, useEffect } from "react"
import { navSections, useNavItemsToDisplay } from "src/Routes/NavItems"
import { LoggedInAppProviders } from "src/providers/LoggedInAppProviders"
import { ScreenSize, useViewport } from "src/utils/useViewport"
import { useOnboardingRedirect } from "src/pages/Onboarding/useOnboardingRedirect"
// eslint-disable-next-line rulesdir/admin-imports
import GhostingStatusBar from "../../../admin/GhostingStatusBar"
import { CurrentUser } from "../../../model/CurrentUser"
import { isEmbed } from "../../../providers/embed/utils"
import { LoggedInUserContext } from "../../../providers/loggedInUser/useLoggedInUser"
import { useUtmTracking } from "../../../utils/hooks/useUtmTracking"
import DataTrialStatusBar from "../../DataTrialStatusBar/DataTrialStatusBar"
import { UserStatusTracker } from "../UserStatusTracker"
import { InformationPopupWrapper } from "../information-popup/InformationPopupWrapper"
import TopNavbar from "../navbar/TopNavbar"
import { useContext } from "react"
import { TopNavbarRefContext } from "src/providers/TopNavbar/TopNavbarRefProvider"
import { ContactDetailsWrapper } from "src/pages/CRM/CRMProviders"
import { SideNavbarRefContext } from "src/providers/SideNavbar/SideNavbarRefProvider"

const useTopNavbarRef = () => {
  const value = useContext(TopNavbarRefContext)?.topNavbarRef
  if (!value) {
    throw new Error("useTopNavbarRef must be used within TopNavbarRefProvider")
  }
  return value
}

const useSideNavbarRef = () => {
  const value = useContext(SideNavbarRefContext)?.sideNavbarRef
  if (!value) {
    throw new Error("useSideNavbarRef must be used within SideNavbarRefProvider")
  }
  return value
}

interface LoggedInLayoutProps {
  currentUser: CurrentUser
  children: ReactNode
}

export const LoggedInLayout = ({ children, currentUser }: LoggedInLayoutProps) => {
  const viewport = useViewport()
  const topNavbarRef = useTopNavbarRef()
  const sideNavbarRef = useSideNavbarRef()
  const { trackUtmCampaign } = useUtmTracking()

  useEffect(trackUtmCampaign, [trackUtmCampaign])

  const navItemsToDisplay = useNavItemsToDisplay({ currentUser })

  useOnboardingRedirect()

  if (isEmbed()) {
    return (
      <LoggedInUserContext.Provider value={currentUser}>
        <LoggedInAppProviders>{children}</LoggedInAppProviders>
      </LoggedInUserContext.Provider>
    )
  }

  return (
    <LoggedInUserContext.Provider value={currentUser}>
      <LoggedInAppProviders>
        <div data-test-id="loggedInLayout" id="logged-in-layout-div" className="flex w-full">
          {viewport.size !== ScreenSize.small && (
            <Navigation
              ref={sideNavbarRef}
              sections={navSections.concat([])}
              items={navItemsToDisplay}
            />
          )}
          <div className="relative flex-1 min-w-0" style={{ overflowX: "clip" }}>
            <div className="sticky top-0 z-50" ref={topNavbarRef}>
              <div className="bg-neutral-white border-b-1 border-neutral-400 shadow-sm">
                <TopNavbar />
              </div>
              <GhostingStatusBar />
              <DataTrialStatusBar />
              <TradingRegistrationStatusBar />
            </div>
            <div>
              <UserStatusTracker user={currentUser} />
              <InformationPopupWrapper />
              <ContactDetailsWrapper>{children}</ContactDetailsWrapper>
            </div>
          </div>
        </div>
      </LoggedInAppProviders>
    </LoggedInUserContext.Provider>
  )
}
