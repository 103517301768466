import ContactInputField from "@stories/components/ContactInputField/ContactInputField"
import { Input } from "@stories/components/Inputs/Input/Input"
import { PercentageField } from "@stories/components/Inputs/NumberInput/PercentageField"
import PhoneInput from "@stories/components/Inputs/PhoneInput/PhoneInput"
import Typography, { Size } from "@stories/components/Typography/Typography"
import { isIntermediaryAccount, isInvestorShareholderAccount } from "common/model/Account"
import { DealCRMContact } from "common/model/DealCRM/DealCRMContact"
import { DealCRMFirmContact, isNonBrokerageFirm } from "common/model/DealCRM/DealCRMFirmContact"
import { DealCRMIndividualContact } from "common/model/DealCRM/DealCRMIndividualContact"
import {
  ManagementFeeStructure,
  managementFeeStructures,
} from "common/model/DealCRM/DealCRMInterest"
import { isLoaded } from "common/utils/Loading"
import { formatAbbreviatedCurrency } from "common/utils/math/format"
import { capitalize, isNil } from "lodash"
import makeAnimated from "react-select/animated"
import { useFirebaseWriter } from "src/firebase/Context"
import { UpdateContactProps, updateContact } from "src/firebase/crm"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { useCurrentAccount } from "src/queries/currentUser/useCurrentAccount"
import {
  NumberField,
  sizeScaledInputWarning,
} from "../../../../../stories/components/Inputs/NumberInput/NumberField"
import { SelectField } from "../../../Components/DealParticipantFieldDisplay/DealParticipantFieldDisplay"
import { StringField } from "../../../Components/InputFields/StringField"
import { useCRMContacts } from "../../../Providers/CRMContactsProvider"
import CRMContactCopyEmail from "../../ContactDisplay/ContactCopyEmail"
import { ContactFirmSelector } from "../ContactFirmSelector"
import { ContentSection } from "./ContentSection"
import { InvestmentPreferences } from "./InvestmentPreferences"

const animatedComponents = makeAnimated()

const sectorOptions = [
  { value: "Fintech", label: "Fintech: Payments" },
  { value: "Aerospace", label: "Aerospace/Defense" },
  { value: "SaaS", label: "SaaS" },
  // Add more sectors as needed
]

export const IndividualContactDetails = ({ contact }: { contact: DealCRMIndividualContact }) => {
  const db = useFirebaseWriter()
  const user = useLoggedInUser()

  const handleUpdateContact = <C extends DealCRMContact>({
    contact: updatedContact,
    update,
  }: UpdateContactProps<C>) =>
    updateContact({ db, contact: updatedContact, update, user: user.user })

  const account = useCurrentAccount()
  const { contacts: allCRMContacts } = useCRMContacts()

  const firm: DealCRMFirmContact | undefined = allCRMContacts.find(
    (c): c is DealCRMFirmContact => c.id === contact.firm?.id && isNonBrokerageFirm(c)
  )

  return (
    <>
      <ContentSection title="Contact Details">
        {contact.firm ||
        (contact.isTransactionalIndividual &&
          contact.classification !== "shareholder" &&
          contact.classification !== "firm_contact_only") ? (
          <>
            <ContactInputField
              label="Firm"
              value={contact.firm?.name}
              error={false}
              variant="select"
            >
              <>
                <ContactFirmSelector<DealCRMIndividualContact>
                  contact={contact}
                  onFirmSelected={(selectedFirm) =>
                    handleUpdateContact({
                      contact,
                      update: {
                        firm: selectedFirm,
                      },
                    })
                  }
                  renderButton={(onClick) => (
                    <Input
                      onFocus={onClick}
                      autoFocus
                      placeholder="Fund/Org"
                      value={contact.firm?.name ?? ""}
                      size="xsmall"
                      readOnly
                    />
                  )}
                />
              </>
            </ContactInputField>
            <ContactInputField label="Title" value={contact.title} error={false} variant="input">
              <StringField
                autoFocus
                placeholder="CEO, Partner, Investor..."
                value={contact.title}
                onChange={(title) =>
                  handleUpdateContact({
                    contact,
                    update: {
                      title: title ?? "",
                    },
                  })
                }
              />
            </ContactInputField>
          </>
        ) : null}

        <ContactInputField
          label="Email"
          value={contact.email}
          error={false}
          variant="input"
          saveMethod="onBlur"
        >
          <div className="flex gap-2 items-center">
            <StringField
              autoFocus
              placeholder="email@company.com"
              value={contact.email}
              onChange={(email) =>
                handleUpdateContact({
                  contact,
                  update: {
                    email: email ?? "",
                  },
                })
              }
            />
            <CRMContactCopyEmail textSize={Size.Small} email={contact.email || ""} iconOnly />
          </div>
        </ContactInputField>
        <ContactInputField
          label="Contact Number"
          value={contact.phone}
          error={false}
          variant="input"
        >
          <PhoneInput
            value={contact.phone ?? undefined}
            onChange={(phoneNumber) =>
              handleUpdateContact({
                contact,
                update: {
                  phone: phoneNumber ?? "",
                },
              })
            }
            heapName="crm-contact-drawer-phone-input"
          />
        </ContactInputField>

        <ContactInputField
          label="Location"
          value={contact.location?.city}
          error={false}
          variant="input"
        >
          <StringField
            autoFocus
            placeholder="Location"
            value={contact.location?.city}
            onChange={(city) =>
              handleUpdateContact({
                contact,
                update: {
                  location: isNil(city)
                    ? {
                        city: "",
                        state: "",
                        country: "",
                      }
                    : {
                        city,
                        state: contact.location?.state ?? "",
                        country: contact.location?.country ?? "",
                      },
                },
              })
            }
          />
        </ContactInputField>
      </ContentSection>

      <ContentSection title="Investment Preferences" divider>
        {contact.isTransactionalIndividual &&
        contact.classification !== "shareholder" &&
        contact.classification !== "firm_contact_only" ? (
          <ContactInputField
            key="targetCheckSize"
            label="Target Check Size"
            check={!!contact.targetCheckSize?.lowerBound && !!contact.targetCheckSize?.upperBound}
            value={
              !!contact.targetCheckSize?.lowerBound || !!contact.targetCheckSize?.upperBound
                ? `${
                    contact.targetCheckSize?.lowerBound
                      ? formatAbbreviatedCurrency(contact.targetCheckSize.lowerBound)
                      : "n/a"
                  } - ${
                    contact.targetCheckSize?.upperBound
                      ? formatAbbreviatedCurrency(contact.targetCheckSize.upperBound)
                      : "n/a"
                  }`
                : ""
            }
            error={
              !!sizeScaledInputWarning(contact.targetCheckSize?.lowerBound ?? undefined) ||
              !!sizeScaledInputWarning(contact.targetCheckSize?.upperBound ?? undefined)
            }
            variant="input"
          >
            <div className="flex gap-4">
              <NumberField
                size="xsmall"
                placeholder="Min"
                value={contact.targetCheckSize?.lowerBound}
                onChange={(lowerBound) =>
                  handleUpdateContact({
                    contact,
                    update: {
                      targetCheckSize: {
                        lowerBound: isNil(lowerBound) ? null : lowerBound,
                        upperBound: contact.targetCheckSize?.upperBound ?? null,
                      },
                    },
                  })
                }
                formatter={formatAbbreviatedCurrency}
                inputWarningFunction={sizeScaledInputWarning}
              />
              <span>-</span>
              <NumberField
                size="xsmall"
                placeholder="Max"
                name="maxCheckSize"
                value={contact.targetCheckSize?.upperBound}
                onChange={(upperBound) =>
                  handleUpdateContact({
                    contact,
                    update: {
                      targetCheckSize: {
                        lowerBound: contact.targetCheckSize?.lowerBound ?? null,
                        upperBound: isNil(upperBound) ? null : upperBound,
                      },
                    },
                  })
                }
                formatter={formatAbbreviatedCurrency}
                inputWarningFunction={sizeScaledInputWarning}
              />
            </div>
          </ContactInputField>
        ) : null}

        {contact.name &&
        isLoaded(account) &&
        isInvestorShareholderAccount(account) &&
        !isIntermediaryAccount(account) &&
        contact.isTransactionalIndividual ? (
          <>
            <ContactInputField
              key="defaultManagementFeeStructure"
              label="Typical Management Fee Structure"
              value={contact.defaultManagementFeeStructure}
              error={false}
              variant="select"
              tooltip="Standard management fee structure you charge this LP. Will be applied by default to new buy-interest you add for this LP."
            >
              <SelectField<ManagementFeeStructure | null>
                size="middle"
                className="min-w-40"
                bordered
                value={contact.defaultManagementFeeStructure ?? null}
                options={[...managementFeeStructures]}
                renderValue={(v) => (
                  <Typography size={Size.Small} text={capitalize(v ?? undefined)} />
                )}
                handleChange={(feeStructure) => {
                  handleUpdateContact({
                    contact,
                    update: {
                      defaultManagementFeeStructure: feeStructure,
                    },
                  })
                  return Promise.resolve()
                }}
                editable
              />
            </ContactInputField>

            <ContactInputField
              key="defaultManagementFeePercent"
              label="Typical Management Fee Percent"
              value={
                contact.defaultManagementFeePercent?.toString()
                  ? `${contact.defaultManagementFeePercent * 100}%`
                  : ""
              }
              error={false}
              variant="input"
              tooltip="Typical management fee you charge this LP. Will be applied by default to new buy-interest you add for this LP."
            >
              <PercentageField
                max={0.1}
                value={contact.defaultManagementFeePercent}
                onChange={(mgmtFee) =>
                  handleUpdateContact({
                    contact,
                    update: {
                      defaultManagementFeePercent: mgmtFee,
                    },
                  })
                }
              />
            </ContactInputField>

            <ContactInputField
              key="defaultCarryPercent"
              label="Typical Carry Percent"
              value={
                contact.defaultCarryPercent?.toString()
                  ? `${contact.defaultCarryPercent * 100}%`
                  : ""
              }
              error={false}
              variant="input"
              tooltip="Typical carry you charge this LP. Will be applied by default to new buy-interest you add for this LP."
            >
              <PercentageField
                value={contact.defaultCarryPercent}
                max={0.4}
                onChange={(carry) =>
                  handleUpdateContact({
                    contact,
                    update: {
                      defaultCarryPercent: carry,
                    },
                  })
                }
              />
            </ContactInputField>
          </>
        ) : null}
        {firm ? (
          <InvestmentPreferences
            contact={firm}
            updateContact={(params) =>
              handleUpdateContact<DealCRMContact>({
                contact: firm,
                update: params.update,
              })
            }
          />
        ) : null}
        {!firm &&
        contact.isTransactionalIndividual &&
        contact.classification !== "shareholder" &&
        contact.classification !== "firm_contact_only" ? (
          <InvestmentPreferences
            contact={contact}
            updateContact={(params) =>
              handleUpdateContact<DealCRMContact>({
                contact,
                update: params.update,
              })
            }
          />
        ) : null}
      </ContentSection>
    </>
  )
}
