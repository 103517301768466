import { useCallback } from "react"
import { useLoggedInUser } from "src/providers/loggedInUser/useLoggedInUser"
import { isLoaded } from "common/utils/Loading"
import { DealCRMIndividualContact } from "common/model/DealCRM/DealCRMIndividualContact"
import { useFirebaseWriter } from "src/firebase/Context"
import { collections } from "common/firestore/Collections"
import { Holding, MinimalHolding, minimalHoldingCRMInterest } from "common/model/holdings/Holding"
import {
  DealCRMContact,
  getCRMContactTagName,
  viewDealCRMContactIdFields,
} from "common/model/DealCRM/DealCRMContact"
import { groupBy } from "lodash"
import { uniqueByRep } from "common/utils/data/Array/ArrayUtils"
import { undefinedsToNulls } from "common/utils/ObjectUtils"
import { DealCRMFirmContact } from "common/model/DealCRM/DealCRMFirmContact"
import { DealCRMNote } from "common/model/DealCRM/DealCRMNote"
import {
  contactToDealCRMNoteContactSource,
  crmInterestToDealCRMNoteInterestSource,
  holdingToDealCRMNoteHoldingSource,
} from "common/model/DealCRM/DealCRMNoteSourceConverters"
import { useCRMContacts } from "src/pages/CRM/Providers/CRMContactsProvider"
import { buildCRMNote } from "../../Notes/createCRMNote"
import compatFirebaseApp from "firebase/compat/app"
import { DealCRMInterest } from "common/model/DealCRM/DealCRMInterest"
import { useAccountSnapshot } from "src/providers/AccountSnapshot/useAccountSnapshot"

export const useCRMUploading = () => {
  const user = useLoggedInUser()
  const { accountSnapshot } = useAccountSnapshot()
  const db = useFirebaseWriter()
  const { findContactByTagName } = useCRMContacts()

  const saveContactBuySellInterestWithNotes = useCallback(
    ({
      contact,
      buySellInterestWithNotes,
      batch,
    }: {
      contact: DealCRMContact
      buySellInterestWithNotes: {
        buySellInterest: DealCRMInterest
        note: Omit<DealCRMNote, "source"> | null
      }[]
      batch: compatFirebaseApp.firestore.WriteBatch
    }) => {
      if (
        buySellInterestWithNotes &&
        buySellInterestWithNotes.length &&
        isLoaded(accountSnapshot)
      ) {
        buySellInterestWithNotes.forEach(({ buySellInterest, note }) => {
          if (!buySellInterest) return
          const finalizedCRMInterest = undefinedsToNulls({
            ...buySellInterest,
            contact: viewDealCRMContactIdFields(contact),
          } satisfies DealCRMInterest)
          batch.set(
            db.db.collection(collections.dealCRM.buySellInterest).doc(finalizedCRMInterest.id),
            finalizedCRMInterest
          )
          if (note) {
            const builtHoldingNote = buildCRMNote({
              content: note.note,
              user: user.user,
              source: crmInterestToDealCRMNoteInterestSource(finalizedCRMInterest),
              mentions: [],
            })
            batch.set(
              db.db.collection(collections.dealCRM.notes).doc(builtHoldingNote.id),
              builtHoldingNote
            )
          }

          batch.set(
            db.db.collection(collections.dealCRM.buySellInterest).doc(finalizedCRMInterest.id),
            finalizedCRMInterest
          )
        })
      }
    },
    [accountSnapshot, db.db, user.user]
  )

  const saveContactHoldingWithNotes = useCallback(
    ({
      contact,
      holdingsWithNotes,
      batch,
    }: {
      contact: DealCRMContact
      holdingsWithNotes: { holding: MinimalHolding; note: Omit<DealCRMNote, "source"> | null }[]
      batch: compatFirebaseApp.firestore.WriteBatch
    }) => {
      if (holdingsWithNotes && holdingsWithNotes.length) {
        holdingsWithNotes.forEach(({ holding, note }) => {
          if (!holding) return
          const finalizedHolding = undefinedsToNulls({
            ...holding,
            shareholder: {
              tag: "crm_contact",
              contact: viewDealCRMContactIdFields(contact),
            },
          } satisfies Holding)
          batch.set(db.db.collection(collections.holdings).doc(holding.id), finalizedHolding)
          if (note) {
            const builtHoldingNote = buildCRMNote({
              content: note.note,
              user: user.user,
              source: holdingToDealCRMNoteHoldingSource(finalizedHolding),
              mentions: [],
            })
            batch.set(
              db.db.collection(collections.dealCRM.notes).doc(builtHoldingNote.id),
              builtHoldingNote
            )
          }
          if (finalizedHolding.intent === "sell" || finalizedHolding.intent === "buy-more") {
            const crmInterest = minimalHoldingCRMInterest({
              ...finalizedHolding,
              intent: finalizedHolding.intent,
            })
            batch.set(
              db.db.collection(collections.dealCRM.buySellInterest).doc(crmInterest.id),
              crmInterest
            )
          }
        })
      }
    },
    [db.db, user.user]
  )

  const saveContactNotes = useCallback(
    ({
      contact,
      notes,
      batch,
    }: {
      contact: DealCRMContact
      notes: Omit<DealCRMNote, "source">[]
      batch: compatFirebaseApp.firestore.WriteBatch
    }) => {
      if (notes && notes.length) {
        notes.forEach((note) => {
          const builtContactNote = buildCRMNote({
            content: note.note,
            user: user.user,
            source: contactToDealCRMNoteContactSource(contact),
            mentions: [],
          })
          batch.set(
            db.db.collection(collections.dealCRM.notes).doc(builtContactNote.id),
            builtContactNote
          )
        })
      }
    },
    [db.db, user.user]
  )

  const saveFirmContacts = useCallback(
    async (
      firm: DealCRMFirmContact | null,
      contacts: {
        contact: DealCRMIndividualContact
        contactNote: Omit<DealCRMNote, "source"> | null
        holding?: MinimalHolding
        holdingNote?: Omit<DealCRMNote, "source"> | null
        buySellInterest?: DealCRMInterest
        buySellInterestNote?: Omit<DealCRMNote, "source"> | null
      }[]
    ) => {
      const batch = db.db.batch()

      if (firm && !findContactByTagName(getCRMContactTagName(firm))) {
        batch.set(db.db.collection(collections.dealCRM.contacts).doc(firm.id), firm)
      }

      const contactsByName = uniqueByRep<DealCRMIndividualContact, string | null>(
        getCRMContactTagName
      )(contacts.map(({ contact }) => contact))
      const dataByContactName = groupBy(
        contacts.map(
          ({
            contact,
            holding,
            contactNote,
            holdingNote,
            buySellInterest,
            buySellInterestNote,
          }) => ({
            contact,
            holding,
            contactNote,
            holdingNote,
            buySellInterest,
            buySellInterestNote,
          })
        ),
        ({ contact }) => getCRMContactTagName(contact)
      )
      contactsByName.forEach((contact) => {
        const existingContact = findContactByTagName(getCRMContactTagName(contact))
        if (!existingContact) {
          batch.set(db.db.collection(collections.dealCRM.contacts).doc(contact.id), contact)
        }
        const contactToUse = existingContact ?? contact

        saveContactNotes({
          contact: contactToUse,
          notes: dataByContactName[getCRMContactTagName(contact)].flatMap(
            ({ contactNote }) => contactNote ?? []
          ),
          batch,
        })

        saveContactHoldingWithNotes({
          contact: contactToUse,
          holdingsWithNotes: dataByContactName[getCRMContactTagName(contact)].flatMap(
            ({ holding, holdingNote }) => (holding ? { holding, note: holdingNote ?? null } : [])
          ),
          batch,
        })

        saveContactBuySellInterestWithNotes({
          contact: contactToUse,
          buySellInterestWithNotes: dataByContactName[getCRMContactTagName(contact)].flatMap(
            ({ buySellInterest, buySellInterestNote }) =>
              buySellInterest ? { buySellInterest, note: buySellInterestNote ?? null } : []
          ),
          batch,
        })
      })

      await batch.commit()
    },
    [
      db.db,
      findContactByTagName,
      saveContactBuySellInterestWithNotes,
      saveContactHoldingWithNotes,
      saveContactNotes,
    ]
  )

  return { saveFirmContacts }
}
