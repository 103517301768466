import { AuthenticatedRouteProps } from "@components/auth/AuthenticatedRoute"
import Admin from "src/admin"
import { customLazyLoad } from "src/utils/CustomLazyLoad"
import { namedImport } from "src/utils/importUtils"
import { withRouteParam } from "../utils"
import { ImportContactsPage } from "src/pages/CRM/ImportContacts/ImportContactsPage"
import { config } from "src/config"
import { InboxPage } from "src/pages/Email/InboxPage"

const DeprecatedWidgetEmbed = customLazyLoad(() =>
  import("../../pages/WidgetEmbeds/components/WidgetEmbed").then(
    namedImport("DeprecatedWidgetEmbed")
  )
)

const CompanyNewsEmbed = customLazyLoad(
  () => import("../../pages/WidgetEmbeds/NewsEmbed/NewsEmbed")
)

const EventFeedPage = customLazyLoad(() => import("../../pages/EventFeedPage"))

const NoTransactions = customLazyLoad(() => import("../../pages/Data/DataUpload/NoTransactions"))

const RFQPage = customLazyLoad(() => import("../../pages/RFQs"))

const StructurePricingTool = customLazyLoad(() => import("../../pages/StructurePricingTool"))

const CompanyPage = customLazyLoad(() => import("../../pages/Companies/CompanyPage"))

const CompanyEditPage = customLazyLoad(() => import("../../pages/Companies/CompanyEditPage"))

const AuctionPage = customLazyLoad(() => import("../../pages/Auctions/AuctionPage"))

const HoldingsOnboardingPage = customLazyLoad(
  () => import("../../pages/Holdings/HoldingsOnboardingPage")
)
const WatchlistPage = customLazyLoad(() => import("../../components/Watchlist/WatchlistPage"))

const OnboardingPage = customLazyLoad(() =>
  import("../../pages/Data/Onboarding/Page").then(namedImport("OnboardingPage"))
)

const AllCompanies = customLazyLoad(() =>
  import("../../pages/Companies/LandingPage/Page").then(namedImport("AllCompanies"))
)

const OrderBookPage = customLazyLoad(() => import("../../pages/Orders/OrderBook/OrderBookTab"))
const MyOrdersPage = customLazyLoad(() => import("../../pages/Orders/MyOrders/MyOrdersPage"))

const OpportunityInboxPage = customLazyLoad(
  () => import("../../pages/Orders/OrderOpportunityInbox/OrderOpportunityInboxPage")
)
const TradeHistoryPage = customLazyLoad(
  () => import("../../pages/ClosedTrades/ClosedTradeHistoryPage/ClosedTradeHistoryPage")
)

const OrderPage = customLazyLoad(() => import("../../pages/Orders/OrderPage/OrderPage"))

const RFQDashboard = customLazyLoad(() => import("../../pages/RFQs/RFQDashboard"))

const Inbox = customLazyLoad(() => import("../../pages/Messaging/Inbox").then(namedImport("Inbox")))

const V3ResultsViewerPage = customLazyLoad(
  () => import("../../pages/RFQs/V3/V3ResultsViewer/V3ResultsViewerPage")
)

const DataUploadPage = customLazyLoad(() =>
  import("../../pages/Data/DataUpload/DataUploadPage").then(namedImport("DataUploadPage"))
)

const AccountSettingsPage = customLazyLoad(() =>
  import("../../pages/UserSettings/Page").then(namedImport("UserSettingsPage"))
)

const InsightsPage = customLazyLoad(() =>
  import("../../pages/Insights/InsightsPage").then(namedImport("InsightsPage"))
)

const NewsPage = customLazyLoad(() =>
  import("../../pages/News/NewsPage").then(namedImport("NewsPage"))
)

const FundingRoundsPage = customLazyLoad(() =>
  import("../../pages/News/FundingRoundsPage").then(namedImport("FundingRoundsPage"))
)

const NotificationsPage = customLazyLoad(() =>
  import("../../pages/Notifications/NotificationsPage").then(namedImport("NotificationsPage"))
)

const AllSectorsPage = customLazyLoad(() => import("../../pages/Sectors/AllSectors"))

const SectorPage = customLazyLoad(() => import("../../pages/Sectors/SectorPage"))

const CRMPage = customLazyLoad(() => import("../../pages/CRM/CRMPage").then(namedImport("CRMPage")))

const AllDealsPage = customLazyLoad(() =>
  import("../../pages/CRM/AllDealsPage").then(namedImport("AllDealsPage"))
)

const AllContactsPage = customLazyLoad(() =>
  import("../../pages/CRM/AllContactsPage").then(namedImport("AllContactsPage"))
)

const AllInterestPage = customLazyLoad(() => import("../../pages/CRM/AllInterestPage"))

const CRMDealDetailsPage = customLazyLoad(() => import("../../pages/CRM/Deals/CRMDealDetailsPage"))

const DealDistributionCreatePage = customLazyLoad(() =>
  import("../../pages/DealDistributions/DealDistributionCreate/DealDistributionCreatePage").then(
    namedImport("DealDistributionCreatePage")
  )
)
const DealDistributionParticipantViewPage = customLazyLoad(() =>
  import(
    "../../pages/DealDistributions/DealDistributionParticipantView/DealDistributionParticipantViewPage"
  ).then(namedImport("DealDistributionViewPage"))
)

const DealDistributionCreatorPreviewPage = customLazyLoad(() =>
  import(
    "../../pages/DealDistributions/DealDistributionParticipantView/DealDistributionCreatorPreviewPage"
  ).then(namedImport("DealDistributionCreatorPreviewPage"))
)

const DealSearchRootPage = customLazyLoad(() => import("../../pages/DealSearch/DealSearchRootPage"))

const DealSearchResultsPage = customLazyLoad(
  () => import("../../pages/DealSearch/DealSearchResultsPage")
)

const ShareholderDashboardPage = customLazyLoad(
  () => import("src/pages/ShareholderExperience/dashboard/ShareholderDashboard")
)
const IndividualHoldingPage = customLazyLoad(
  () => import("src/pages/ShareholderExperience/dashboard/IndividualHolding")
)

export const authRoutes: AuthenticatedRouteProps[] = [
  {
    route: (routes) => routes.account,
    component: AccountSettingsPage,
  },
  {
    route: (routes) => routes.insights,
    component: InsightsPage,
  },
  {
    route: (routes) => routes.news,
    component: NewsPage,
  },
  {
    route: (routes) => routes.fundingRounds,
    component: FundingRoundsPage,
  },
  {
    route: (routes) => routes.notifications,
    component: NotificationsPage,
  },
  {
    route: withRouteParam((routes) => routes.auction, "auctionId"),
    component: AuctionPage,
  },
  {
    route: (routes) => routes.rfqs?.root,
    component: RFQDashboard,
  },
  {
    route: (routes) => routes.inbox?.root,
    component: Inbox,
  },
  {
    route: withRouteParam((routes) => routes.rfqs?.rfq, "rfqId"),
    component: RFQPage,
  },
  {
    route: withRouteParam((routes) => routes.rfqs?.results, "rfqId"),
    component: V3ResultsViewerPage,
  },
  {
    route: (routes) => routes.data?.upload?.noTransactions,
    component: NoTransactions,
  },
  {
    route: (routes) => routes.data?.upload?.root,
    component: DataUploadPage,
  },
  {
    route: (routes) => routes.data?.onboarding,
    component: OnboardingPage,
  },
  {
    route: (routes) => routes.companies?.root,
    component: AllCompanies,
  },
  {
    route: withRouteParam((routes) => routes.companies?.companyEdit, "companyId"),
    component: CompanyEditPage,
  },
  {
    route: withRouteParam((routes) => routes.companies?.company, "companyId"),
    component: CompanyPage,
  },

  {
    route: (routes) => routes.admin?.root,
    component: Admin,
  },
  {
    route: (routes) => routes.structurePricer,
    component: StructurePricingTool,
  },
  {
    route: withRouteParam((routes) => routes.holding, "holdingId"),
    component: HoldingsOnboardingPage,
  },
  {
    route: (routes) => routes.marketPriceEmbed,
    component: DeprecatedWidgetEmbed,
  },
  {
    route: (routes) => routes.companyNewsEmbed,
    component: CompanyNewsEmbed,
  },
  {
    route: (routes) => routes.orders?.root,
    component: OrderBookPage,
  },
  {
    route: (routes) => routes.myOrders,
    component: MyOrdersPage,
  },
  {
    route: (routes) => routes.opportunityInbox,
    component: OpportunityInboxPage,
  },
  { route: (routes) => routes.closedTrades?.root, component: TradeHistoryPage },
  {
    route: withRouteParam((routes) => routes.orders?.order, "orderId"),
    component: OrderPage,
  },
  {
    route: (routes) => routes.eventFeed,
    component: EventFeedPage,
  },
  {
    route: (routes) => routes.watchlistPage,
    component: WatchlistPage,
  },
  {
    route: withRouteParam((routes) => routes.sectors?.sector, "sectorId"),
    component: SectorPage,
  },
  {
    route: (routes) => routes.sectors?.root,
    component: AllSectorsPage,
  },
  {
    route: (routes) => routes.crm?.root,
    component: CRMPage,
  },
  {
    route: (routes) => routes.crm?.allDeals,
    component: AllDealsPage,
  },
  {
    route: (routes) => routes.crm?.allInterest,
    component: AllInterestPage,
  },
  {
    route: (routes) => routes.crm?.allContacts,
    component: AllContactsPage,
  },
  {
    route: (routes) => routes.crm?.contacts?.import,
    component: ImportContactsPage,
  },
  {
    route: withRouteParam((routes) => routes.crm?.deal, "dealId"),
    component: CRMDealDetailsPage,
  },
  {
    route: (routes) => routes.email?.inbox,
    component: config.env === "prod" ? () => null : InboxPage,
  },
  {
    route: (routes) => routes.crm?.dealDistribution?.pendingNew,
    component: DealDistributionCreatePage,
  },
  {
    route: withRouteParam((routes) => routes.crm?.dealDistribution?.new, "dealDistributionId"),
    component: DealDistributionCreatePage,
  },
  {
    route: withRouteParam(
      (routes) => routes.crm?.dealDistribution?.participantView,
      "dealDistributionParticipantId"
    ),
    component: DealDistributionParticipantViewPage,
  },
  {
    route: withRouteParam(
      (routes) => routes.crm?.dealDistribution?.creatorPreview,
      "dealDistributionId"
    ),
    component: DealDistributionCreatorPreviewPage,
  },
  {
    route: (routes) => routes.dealSearch?.root,
    component: DealSearchRootPage,
  },
  {
    route: withRouteParam((routes) => routes.dealSearch?.results, "dealSearchId"),
    component: DealSearchResultsPage,
  },
  {
    route: (routes) => routes.shareholders?.portfolio,
    component: ShareholderDashboardPage,
  },
  {
    route: withRouteParam((routes) => routes.shareholders?.portfolioHolding, "holdingId"),
    component: IndividualHoldingPage,
  },
]
