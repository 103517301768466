import Spinner from "../../../../components/icons/Spinner"
import { useLinkedBrokersQuery } from "src/queries/LinkedBroker/useLinkedBrokersQuery"
import { LinkedBroker } from "common/model/LinkedBroker"
import { createColumnHelper } from "@stories/components/Table"
import { useFirebase9 } from "src/firebase/Firebase9Context"
import { collections } from "common/firestore/Collections"
import { deleteDoc, doc } from "firebase/firestore"
import { handleConsoleError } from "src/utils/Tracking"
import { Button } from "@stories/components/Button/Button"
import { notification } from "@stories/components/Antd/notification"
import Table from "@stories/components/Table/Table"
import { isLoaded } from "common/utils/Loading"

const AdminDeleteLinkedBrokerCell = ({ linkedBroker }: { linkedBroker: LinkedBroker }) => {
  const { db } = useFirebase9()
  const onDeleteLinkedBroker = () => {
    if (
      window.confirm(
        `Are you sure you want to delete the Linked Broker named ${linkedBroker.fullName}? This will only delete them from the linked broker list. If they are on the platform, their User and Account will remain unchanged. This currently cannot be undone, though we will have tools to re-add them to the list soon.`
      )
    ) {
      deleteDoc(doc(db, collections.linkedBrokers, linkedBroker.id))
        .then(() => {
          notification.success({
            message: `${linkedBroker.fullName} was successfully removed from linked broker list.`,
            placement: "top",
          })
        })
        .catch(handleConsoleError)
    }
  }
  return <Button label="Remove from List" onClick={onDeleteLinkedBroker} variant="hollow" />
}

const columnHelper = createColumnHelper<LinkedBroker>()

const columns = [
  columnHelper.accessor("firmName", {
    id: "firmName",
    header: "Firm",
    cell: (context) => context.row.original.firmName,
    footer: (props) => props.column.id,
  }),
  columnHelper.accessor("fullName", {
    id: "fullName",
    header: "Name",
    cell: (context) => context.row.original.fullName,
    footer: (props) => props.column.id,
  }),
  columnHelper.accessor("user", {
    id: "user",
    header: "On Platform",
    cell: (context) => (context.row.original.user ? "Yes" : "No"),
    footer: (props) => props.column.id,
    sortUndefined: "last",
  }),
  columnHelper.display({
    id: "delete",
    header: "Delete",
    cell: (context) => <AdminDeleteLinkedBrokerCell linkedBroker={context.row.original} />,
  }),
]

const AdminLinkedBrokersTable = () => {
  const loadingLinkedBrokers = useLinkedBrokersQuery(undefined)

  if (!isLoaded(loadingLinkedBrokers)) return <Spinner size="lg" />

  return (
    <Table columns={columns} data={loadingLinkedBrokers} globalFilter={{ placeholder: "Search" }} />
  )
}

export default AdminLinkedBrokersTable
