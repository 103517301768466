export const link = (href: string, text: string) => `<${href}|${text}>`
export const codeBlock = (text: string) => `\`\`\`${text}\`\`\``
export const jsonCodeBlock = (obj: unknown) => codeBlock(JSON.stringify(obj, null, 2))

export const SLACK_USERS = {
  Olga: "<@U029MRMEWR2>",
  Justin: "<@U016QHKAR8T>",
  Chris: "<@U032LSLE3R9>",
  Josh: "<@U03KDUKJX88>",
  Maui: "<@U05LKKTCC3B>",
  Javi: "<@U017E6NHKQ8>",
  BisDev: "<!subteam^S032MPJJA15>",
}
